import React from 'react';
import PropTypes from 'prop-types';
import {
    GoThumbsdown,
    GoThumbsup
} from "react-icons/go";
// const Review = ({ review }) => (
//   <div className="review">
//     <div className="review-header">
//       <span className="reviewer-name">{review.name}</span>
//       <span className="review-rating">Rating: {review.rating} ⭐</span>
//     </div>
//     <div className="review-body">
//       <p>{review.text}</p>
//     </div>
//     <div className="review-footer">
//       <span className="review-date">{review.date}</span>
//     </div>
//   </div>
// );
const verifiedIcon = 'https://project543.createinfotech.com' + '/assets/righticon.png';

const Review = ({
    review
}) => {
    return ( 
        <>
        <div >
        </div> 
        <div className = "product-review" >
        <div className = "review-content" >
        <div className = "product-rating"
        style = {
            {
                justifyContent: "space-between"
            }
        } > {
            '★'.repeat(Math.floor(4))
        } {
            '☆'.repeat(5 - Math.floor(4))
        } 
        </div> 
        <div className = "review-for" >
        <p> Review
        for: {
            review.product
        } </p> 
        </div> 
        <div className = "review-text" >
        <p> {
            review.reviewText
        } </p> 
        </div> 
        <div className = 'review-footer' >
        < div >
        <span className = "reviewer-name" > {
            review.reviewer
        } </span> 
        </div> 
        <div className = 'd-flex'
        style = {
            {
                marginTop: '10px'
            }
        } >
        < button className = 'btn-save'
        style = {
            {
                border: "1px solid gray",
                maxWidth: "165px"
            }
        } > < GoThumbsup size = {
            15
        }
        /> Helpful for {review.helpfulCount}</button >
        <button className = 'btn-save'
        style = {
            {
                border: "1px solid gray",
                maxWidth: "75px"
            }
        } > < GoThumbsdown size = {
            15
        }
        /> {review.unhelpfulCount}</button >
        </div> 
        <div style = {
            {
                marginTop: '10px',
                width: "100%"
            }
        } >
        <span className = 'verified-purchase' > {
            review.verified && (
                 <>
                <img src = {
                    verifiedIcon
                }
                style = {
                    {
                        marginRight: "5px"
                    }
                }
                />Verified Purchase. 
                <span style = {
                    {
                        marginLeft: "5px"
                    }
                } > {
                    " " + review.daysAgo
                }
                days ago </span> </>
            )
        } 
        </span> 
        </div> 
        </div>
        </div> 
        </div> 
        </>

    );
};

Review.propTypes = {
    review: PropTypes.shape({
        name: PropTypes.string.isRequired,
        rating: PropTypes.number.isRequired,
        text: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
    }).isRequired,
};

export default Review;