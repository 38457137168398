import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import ReviewsList from "./ReviewsList";
import { Link, useNavigate } from "react-router-dom";
import queryString from "query-string";
import Loader from "./loader";
import { FaHeart } from "react-icons/fa"; // Import the filled heart icon
import { setUserId, getUserId, clearUserId } from "./localStorageUtils";
import { PiShoppingCart } from "react-icons/pi";
import { FaRegCircleUser } from "react-icons/fa6";
import { IoSearchOutline } from "react-icons/io5";
import { BiArrowBack } from "react-icons/bi";
import { HiArrowUturnLeft, HiMiniArrowUturnLeft } from "react-icons/hi2";
import { GoArrowLeft } from "react-icons/go";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import SwiperCore, { Navigation, Pagination } from "swiper";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const FormData = require("form-data");

const ProductPage = () => {
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [color, setcolor] = useState([]);
  const [size, setsize] = useState([]);
  const [storage, setstorage] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userId, setUserIdState] = useState(null);
  const [relatedProduct, setRelatedProduct] = useState([]);
  const navigate = useNavigate();
  const flipcartLogo =
    "https://project543.createinfotech.com" + "/assets/Flipkart-logo.png";
  const plus = "https://project543.createinfotech.com" + "/assets/plus.jpg";
  const progressImage =
    "https://project543.createinfotech.com" + "/assets/progress.png";
  const freeDelivery =
    "https://project543.createinfotech.com" + "/assets/freedelivery.png";
  const cashBack = "https://project543.createinfotech.com" + "/assets/coin.png";
  const cashBack2 =
    "https://project543.createinfotech.com" + "/assets/coin2.png";
  const wowIcon =
    "https://project543.createinfotech.com" + "/assets/wowIcon.png";
  const arrow1 =
    "https://project543.createinfotech.com" + "/assets/arrow-1.png";
  const backArrow =
    "https://project543.createinfotech.com" + "/assets/backarrow.png";
  const righticonSimilar =
    "https://project543.createinfotech.com" + "/assets/righticonSimilar.png";
  const [products, setProducts] = useState([]);
  const [visibleProducts, setVisibleProducts] = useState(10);
  const [cartQuantity, setcartQuantity] = useState(0);
  const [showBottomSheet, setShowBottomSheet] = useState(false);
  const [bottomSheetContent, setBottomSheetContent] = useState("");
  const [selectedColor, setSelectedColor] = useState(
    color.length > 0 ? color[0].title : ""
  ); // Initial color
  const [selectedStorage, setSelectedStorage] = useState(
    storage.length > 0 ? storage[0].title : ""
  );
  const [selectedsize, setSelectedsize] = useState(
    size.length > 0 ? size[0].title : ""
  );

  const handleOptionClick = (content) => {
    // setBottomSheetContent(content);
    setShowBottomSheet(true);
  };

  const handleColorSelect = (colorTitle) => {
    setSelectedColor(colorTitle); // Update the selected color name
    // setShowBottomSheet(false); // Close the bottom sheet after selection
  };

  const handleStorageSelect = (storageTitle) => {
    setSelectedStorage(storageTitle); // Update the selected storage
    // setShowBottomSheet(false); // Close the bottom sheet after selection
  };

  const handlesizeSelect = (sizeTitle) => {
    setSelectedsize(sizeTitle); // Update the selected storage
    // setShowBottomSheet(false); // Close the bottom sheet after selection
  };

  const closeBottomSheet = () => {
    setShowBottomSheet(false);
  };

  const cencelBottomSheet = () => {
    setSelectedColor(storage.length > 0 ? storage[0].title : "");
    setSelectedStorage(storage.length > 0 ? storage[0].title : "");
    setSelectedsize(size.length > 0 ? size[0].title : "");
    setShowBottomSheet(false);
  };
  const repeatProducts = (products, times) => {
    const extendedProducts = [];
    for (let i = 0; i < times; i++) {
      extendedProducts.push(
        ...products.map((product) => ({ ...product, id: `${product.id}-${i}` }))
      );
    }
    return extendedProducts;
  };

  // Extended products list to show 100 times
  const extendedProducts = repeatProducts(products, 100);

  useEffect(() => {
    // const storedUserId = getUserId();
    // setUserIdState(storedUserId);

    const fetchData = async () => {
      // if (!storedUserId) return;

      try {
        const storedUserId = getUserId();
        let data = new FormData();
        data.append('user_id', storedUserId);

        let config = {
          method: 'post',
          url: 'https://salertop.shop/admin/api/v1/list_cart',
          headers: {
            'Authorization': 'Basic ZmxpcGthcnQ6MjAkdGdic3YwOXU=',
          },
          data: data
        };

        const response = await axios.request(config);
        setcartQuantity(response.data.qty);
        // setCartItems(response.data.data || []);
        // setTotalPrice(response.data.total);
      } catch (err) {
        console.error('API request error:', err.response ? err.response.data : err.message);
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (showBottomSheet) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }

    // Cleanup function to remove scroll lock when component unmounts
    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [showBottomSheet]);

  const gotocart = () => {
    navigate('/cart/');
  };

  const handleAddToCart = (productId) => {
    if (!productId) {
      console.error("Product ID is null or undefined");
      return;
    }

    const fetchData = async () => {
      const storedUserId = getUserId();

      try {
        let data = new FormData();
        data.append("product_id", productId);
        data.append("user_id", storedUserId);

        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: "https://salertop.shop/admin/api/v1/add_to_cart",
          headers: {
            Authorization: "Basic ZmxpcGthcnQ6MjAkdGdic3YwOXU=",
          },
          data: data,
        };

        axios
          .request(config)
          .then(async (response) => {
            console.log("Product added to cart:", response.data.message);
            // Redirect to the cart page
            // navigate(`/cart/`);
            try {
              const storedUserId = getUserId();
              let data = new FormData();
              data.append('user_id', storedUserId);

              let config = {
                method: 'post',
                url: 'https://salertop.shop/admin/api/v1/list_cart',
                headers: {
                  'Authorization': 'Basic ZmxpcGthcnQ6MjAkdGdic3YwOXU=',
                },
                data: data
              };

              const response = await axios.request(config);
              setcartQuantity(response.data.qty);
              // setCartItems(response.data.data || []);
              // setTotalPrice(response.data.total);
            } catch (err) {
              console.error('API request error:', err.response ? err.response.data : err.message);
              setError(err);
            } finally {
              setLoading(false);
            }
            setLoading(false);
          })
          .catch((error) => {
            console.log(error);
          });
        toast.success('Item Add successfully!', {
          position: 'top-center',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: { backgroundColor: 'black', color: 'white' },
        });
      } catch (err) {
        console.error(
          "API request error:",
          err.response ? err.response.data : err.message
        );
        setError(err);
        setLoading(false);
      }
    };

    fetchData();
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleBuyNow = (productId) => {
    if (!productId) {
      console.error("Product ID is null or undefined");
      return;
    }

    const fetchData = async () => {
      try {
        const storedUserId = getUserId();
        setUserIdState(storedUserId);
        let data = new FormData();
        data.append("product_id", productId);
        data.append("user_id", storedUserId);

        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: "https://salertop.shop/admin/api/v1/add_to_cart",
          headers: {
            Authorization: "Basic ZmxpcGthcnQ6MjAkdGdic3YwOXU=",
          },
          data: data,
        };

        axios
          .request(config)
          .then((response) => {
            console.log("Product added to cart:", response);
            // Redirect to the cart page
            navigate(`/cart/`);
            setLoading(false);
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (err) {
        console.error(
          "API request error:",
          err.response ? err.response.data : err.message
        );
        setError(err);
        setLoading(false);
      }
    };

    fetchData();
  };

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + "";
    }
    return text;
  };

  const handleAllProducts = () => {
    navigate("/product");
  };

  useEffect(() => {
    const storedUserId = getUserId();
    setUserIdState(storedUserId);

    const fetchProduct = async () => {
      try {
        let data = new FormData();
        data.append("product_id", id);

        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: "https://salertop.shop/admin/api/v1/single_product",
          headers: {
            Authorization: "Basic ZmxpcGthcnQ6MjAkdGdic3YwOXU=",
          },
          data: data,
        };

        axios
          .request(config)
          .then((response) => {
            setProduct(response.data.data.product);
            setcolor(response.data.data.color || []);
            setsize(response.data.data.size || []);
            setstorage(response.data.data.storage || []);
            setRelatedProduct(response.data.data.related_products || []);
            setLoading(false);
            setSelectedColor(storage.length > 0 ? storage[0].title : "");
            setSelectedStorage(storage.length > 0 ? storage[0].title : "");
            setSelectedsize(size.length > 0 ? size[0].title : "");
          })
          .catch((error) => {
            setError("Product not found");
            console.log(error);
          });
      } catch (error) {
        setError("Error fetching product data");
      }
    };

    fetchProduct();
  }, [id]);

  if (loading) return <Loader />;
  if (error) return <p> Error loading data: {error.message} </p>;

  return (
    <>
      <div>
        <div
          style={{
            display: "flex",
            height: "52px",
            alignItems: "center",
            paddingLeft: "10px",
            paddingRight: "20px",
            justifyContent: "space-between",
          }}
        >
          <div
            className="d-flex"
            style={{
              gap: 6,
            }}
          >
            <Link to={`/`}>
              <GoArrowLeft
                size={25}
                className="pr-2"
                color="black"
                style={{
                  marginRight: "10px",
                }}
              />
              <img width={24} height={24} src={flipcartLogo} alt="" />
            </Link>
          </div>
          <div className="header-icons">
            <div
              className="d-flex"
              style={{
                alignItems: "center",
              }}
            >
              <button className="icon-button-item">
                <IoSearchOutline size={20} color="black" />
              </button>
              <button className="icon-button-item" onClick={gotocart}>
                <PiShoppingCart />
                {cartQuantity > 0 && <span className="cart-quantity">{cartQuantity}</span>}
              </button>
            </div>
            <div className="icon-item">
              <span>Login</span>
            </div>
          </div>
        </div>
      </div>
      <div className="product-page">
        <div >
          <img
            src={`${product?.image}`}
            alt={product?.product_name}
            className="main-image"
          />
          <div className="thumbnail-images">
            {" "}
            {product?.image2 && (
              <img
                src={`${product?.image2}`}
                alt="Thumbnail 1"
                className="thumbnail"
              />
            )}{" "}
            {product?.image3 && (
              <img
                src={`${product?.image3}`}
                alt="Thumbnail 2"
                className="thumbnail"
              />
            )}{" "}
            {product?.image4 && (
              <img
                src={`${product?.image4}`}
                alt="Thumbnail 3"
                className="thumbnail"
              />
            )}{" "}
          </div>
          <div
            style={{
              alignItems: "center",
              gap: 10,
              margin: "17px",
              display: "flex",
            }}
          >
            <img
              src={progressImage}
              style={{
                height: "25px",
                width: "25px",
              }}
            />
            <span
              style={{
                fontSize: "15px",
              }}
            >
              {" "}
              315 people ordered this in the last 16 days{" "}
            </span>{" "}
          </div>
          <div className="variant-selection">
            <h3 className="variant-title">Select Variant</h3>
            {color.length > 0 && (
              <div
                className="variant-option"
                onClick={() => handleOptionClick()}
              >
                {color.length > 0 && (
                  <span className="option-name">
                    Color:&nbsp;&nbsp;{selectedColor}
                  </span>
                )}
                {color.length > 0 && (
                  <span className="option-more">
                    {Number(color.length - 1)} more {">"}
                  </span>
                )}
              </div>
            )}
            {storage.length > 0 && (
              <div
                className="variant-option"
                onClick={() => handleOptionClick()}
              >
                {storage.length > 0 && (
                  <span className="option-name">
                    Storage:&nbsp;&nbsp;{selectedStorage} Gb
                  </span>
                )}
                {storage.length > 0 && (
                  <span className="option-more">
                    {Number(storage.length - 1)} more {">"}
                  </span>
                )}
              </div>
            )}
            {size.length > 0 && (
              <div
                className="variant-option"
                onClick={() => handleOptionClick()}
              >
                {size.length > 0 && (
                  <span className="option-name">
                    Size:&nbsp;&nbsp;{selectedsize}
                  </span>
                )}
                {size.length > 0 && (
                  <span className="option-more">
                    {Number(size.length - 1)} more {">"}
                  </span>
                )}
              </div>
            )}
            {showBottomSheet && (
              <div className="bottom-sheet">
                <div
                  className="bottom-sheet-content"
                  style={{ paddingLeft: "10px" }}
                >
                  <span
                    className="bottom-sheet-close"
                    onClick={closeBottomSheet}
                  >
                    X
                  </span>
                  <h3>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Select Variant</h3>
                  <div className="bottom-sheet-divider"></div>
                  <span style={{ padding: "10px" }}>
                    {product?.product_name}
                  </span>
                  <div className="price-info" style={{ padding: "10px" }}>
                    <span className="discount">{product?.off_per} % Off</span>
                    <span className="original-price">
                      ₹{product?.cancle_price}
                    </span>
                    <span className="current-price">₹{product?.price}</span>
                  </div>
                  <div className="bottom-sheet-divider"></div>
                  <div>
                    {color.length > 0 && (
                      <ul className="color-list">
                        <h3 className="select-color">Select Color:</h3>
                        <div className="scroll-container">
                          <div className="d-flex">
                            {color.map((c) => (
                              <li
                                key={c.id}
                                className="color-item"
                                onClick={() => handleColorSelect(c.title)}
                              >
                                <img
                                  src={`${c.image}`}
                                  alt={c.title}
                                  className="color-image"
                                />
                                <span className="color-title">{c.title}</span>
                              </li>
                            ))}
                          </div>
                        </div>
                      </ul>
                    )}
                  </div>

                  <div>
                    {size.length > 0 && (
                      <ul className="option-list">
                        <h3 style={{ marginBottom: "5px" }}>Select Size:</h3>
                        <div className="d-flex" style={{ flexWrap: "wrap" }}>
                          {size.map((s) => (
                            <li
                              key={s.id}
                              className="option-item"
                              onClick={() => handlesizeSelect(s.title)}
                            >
                              {s.title}
                            </li>
                          ))}
                        </div>
                      </ul>
                    )}
                  </div>

                  <div>
                    {storage.length > 0 && (
                      <ul className="option-list">
                        <h3 className="select-sto">Select Storage</h3>
                        <div className="scroll-container">
                          <div className="d-flex">
                            {storage.map((s) => (
                              <li
                                key={s.id}
                                className="option-item"
                                onClick={() => handleStorageSelect(s.title)}
                              >
                                {s.title} GB
                              </li>
                            ))}
                          </div>
                        </div>
                      </ul>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="product-details">
          <div
            style={{
              paddingLeft: "16px",
              paddingRight: "16px",
              boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 8px",
              marginBottom: "9px",
            }}
          >
            <h1
              className="product-page-name"
              style={{
                margin: 0,
              }}
            >
              {" "}
              {product?.product_name}{" "}
            </h1>
            <p
              style={{
                margin: "0px",
              }}
            >
              <div
                className="product-rating"
                style={{
                  justifyContent: "space-between",
                }}
              >
                {" "}
                {"★".repeat(Math.floor(4))} {"☆".repeat(5 - Math.floor(4))}
                <img
                  src={plus}
                  style={{
                    height: "15px",
                    width: "auto",
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span className="discount"> {product?.off_per} % Off </span>
                <span className="original-price">
                  {" "}
                  ₹{product?.cancle_price}{" "}
                </span>
                <span className="current-price"> ₹{product?.price} </span>
              </div>
              <div
                style={{
                  padding: "15px",
                  paddingLeft: "0",
                  fontSize: "0.8em",
                }}
              >
                <span>Free delivery by 29 th Aug </span>
              </div>
            </p>
          </div>

          <div
            style={{
              padding: "4px 12px 8px 16px",
              boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 8px",
              marginBottom: "9px",
            }}
          >
            {" "}
            {/* First Row */}
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                padding: "6px 0",
                gap: "10px",
              }}
            >
              <div
                style={{
                  width: "40px",
                }}
              >
                <img src={freeDelivery} width={25} height={25} />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "calc(100% - 40px)",
                }}
              >
                <div
                  style={{
                    width: "calc(100% - 14px)",
                  }}
                >
                  <p
                    style={{
                      fontSize: "15px",
                      fontWeight: "600",
                    }}
                  >
                    <span
                      style={{
                        color: "#008C00",
                      }}
                    >
                      {" "}
                      FREE Delivery{" "}
                    </span>
                    <span
                      style={{
                        color: "#717478",
                        fontWeight: "normal",
                        textDecoration: "line-through",
                        marginLeft: "4px",
                        marginRight: "5px",
                        borderRight: "2px solid #D5D7DB",
                        paddingRight: "5px",
                      }}
                    >
                      {" "}
                      ₹42{" "}
                    </span>
                    <span>
                      Delivery in{" "}
                      {/* <img src={arrow1} width={15} height={15} /> */}{" "}
                    </span>
                    <span
                      style={{
                        fontSize: "14px",
                        fontWeight: "600",
                        color: "#111112",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div>7 Days, Saturday </div>{" "}
                    </span>{" "}
                  </p>{" "}
                </div>
                <img src={arrow1} width={12} height={12} />
                <button
                  type="button"
                  style={{
                    width: "fit-content",
                    height: "auto",
                    background: "transparent",
                    border: "none",
                    outline: "none",
                    margin: 0,
                    padding: 0,
                  }}
                ></button>
              </div>
            </div>
            {/* Second Row */}
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                padding: "6px 0",
              }}
            >
              <div
                style={{
                  width: "40px",
                }}
              >
                <img
                  src={backArrow}
                  width={25}
                  height={25}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                />{" "}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    gap: "10px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <p
                    style={{
                      fontSize: "14px",
                      color: "#111112",
                    }}
                  >
                    {" "}
                    10 Days Return Policy{" "}
                  </p>
                  <img
                    src={arrow1}
                    width={12}
                    height={12}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  />{" "}
                </div>
                <button
                  type="button"
                  style={{
                    width: "fit-content",
                    height: "auto",
                    background: "transparent",
                    border: "none",
                    outline: "none",
                    margin: 0,
                    padding: 0,
                  }}
                ></button>
              </div>
            </div>
          </div>
          <div
            style={{
              padding: "16px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <img
                src={wowIcon}
                width={50}
                height={50}
                style={{
                  marginRight: "10px",
                }}
              />
              <div>
                <span
                  style={{
                    fontSize: "16px",
                    fontWeight: "500",
                  }}
                >
                  Get at{" "}
                  <span
                    style={{
                      fontWeight: "600",
                      color: "rgba(0,140,0,1)",
                      marginLeft: "5px",
                      fontWeight: "500",
                    }}
                  >
                    {" "}
                    ₹{product?.price}{" "}
                  </span>
                  <p
                    style={{
                      fontSize: "12px",
                      margin: "0px",
                      marginTop: "10px",
                    }}
                  >
                    {" "}
                    With Bank offer{" "}
                  </p>
                </span>
              </div>
            </div>
          </div>
          <div className="coupon">
            <span
              style={{
                fontSize: "0.9em",
              }}
            >
              All Offers & Coupons{" "}
            </span>{" "}
            {/* <img src={arrow1} width={12} height={12} /> */}{" "}
          </div>
          <div
            style={{
              boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 8px",
              marginBottom: "9px",
              paddingBottom: "10px",
            }}
          >
            <div className="cashback">
              <span className="cashback-text">
                <img
                  src={cashBack2}
                  width={22}
                  height={22}
                  style={{
                    marginRight: "7px",
                  }}
                />
                You will earn{" "}
              </span>
              <span className="cashback-text">
                <img
                  src={cashBack}
                  width={15}
                  height={15}
                  style={{
                    marginRight: "7px",
                    marginLeft: "7px",
                  }}
                />
                50 SuperCoins cashback{" "}
              </span>{" "}
            </div>
            <div
              style={{
                paddingLeft: "16px",
                paddingRight: "16px",
              }}
            >
              <p
                style={{
                  borderTop: "1px solid rgba(227,227,227,1)",
                  fontSize: "0.8em",
                  paddingTop: "0.5rem",
                }}
                className="robotofon"
              >
                Use it to save on your next order.
                <span
                  style={{
                    color: "rgba(42,85,229,1)",
                    fontSize: "1em",
                    fontWeight: "500",
                    marginLeft: "3px",
                  }}
                >
                  How ?
                </span>
              </p>
            </div>
          </div>
          <div className="product-review">
            <ReviewsList productName={product?.product_name} />
          </div>
          {!showBottomSheet && (
            <div className="similar-main" style={{ boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 8px", marginBottom: "9px" }}>
              <span style={{ paddingBottom: "15px", fontWeight: "500", fontSize: "1rem", color: "rgba(0,0,0,1)" }}>
                Similar Products
              </span>
              <div className="related-product">
                <section>
                  <ul>
                    <div className="product-grid">
                      {relatedProduct.map((product) => {
                        const rating = Math.floor(Math.random() * (5 - 3 + 1)) + 3;
                        const handleProductClick = () => {
                          setLoading(true);
                          window.scrollTo(0, 0);
                        };
                        return (
                          <li key={product.id} className="product-card">
                            <Link to={`/product/${product.id}`} onClick={handleProductClick}>
                              <div className="product-image-container">
                                <img src={product.image} alt={product?.product_name} className="product-image" />
                                <div className="heart-icon-container">
                                  <FaHeart className="heart-icon" />
                                </div>
                                <div className="heart-icon-container bottom-left">
                                  <div className='css-175oi2r66' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                    <div className='g6gc556' style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                      <img src="https://rukminim1.flixcart.com/www/50/50/promos/22/09/2023/8f8ed4e7-5572-4756-bbec-19df46cbac7d.png?q=90" loading="eager" alt="" style={{ width: 'auto', height: '60%' }} />
                                    </div>
                                  </div>

                                </div>
                              </div>
                              <div style={{ padding: "10px" }}>
                                <p className="product-name robotofont" data-max-length="20" style={{ margin: 0 }}>
                                  {truncateText(product?.product_name)}
                                </p>
                                <p className="product-price">
                                  <span className="discount-off" style={{ fontSize: "20px", fontWeight: "800" }}>↓</span>
                                  <span className="discount-off">{product?.off_per}%</span>&nbsp;
                                  <p className="product-cancel-price">{product?.cancle_price}</p>
                                  <span className="discount-price">&nbsp;₹{product?.price}.00</span>
                                </p>
                                <span style={{ color: "green", backgroundColor: "#d4f8d4", padding: "3px 5px", borderRadius: "5px", fontWeight: "bold", fontSize: "10px" }}>
                                  Hot Deal
                                </span>
                                <div className="product-rating">{"★".repeat(rating)}{"☆".repeat(5 - rating)}</div>
                                <div className="assured">
                                  <span className="discount-off-2">Free delivery</span>
                                </div>
                              </div>
                            </Link>
                          </li>
                        );
                      })}
                    </div>
                  </ul>
                </section>
              </div>
            </div>
          )}
        </div>
        <div className="product-images">
          <div className="mt-3" style={{ textAlign: "left !important" }} dangerouslySetInnerHTML={{ __html: product?.description, style: { textAlign: "start" } }} /> </div>

        {showBottomSheet && (
          <div className="bottom-buttons">
            <button onClick={() => cencelBottomSheet()} className="add-to-cart">
              {" "}
              cancel{" "}
            </button>
            <button onClick={() => closeBottomSheet()} className="buy-now">
              {" "}
              Apply{" "}
            </button>
          </div>
        )}
        {!showBottomSheet && (
          <div className="bottom-buttons">
            <button
              onClick={() => handleAddToCart(product.id)}
              className="add-to-cart"
            >
              {" "}
              Add to Cart{" "}
            </button>
            <button
              onClick={() => handleBuyNow(product.id)}
              className="buy-now"
            >
              {" "}
              Buy Now{" "}
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default ProductPage;
