import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const ImageSlider = ({
    sliderImages
}) => {
    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true, // Enables autoplay
        autoplaySpeed: 3000,
    };

    return ( <
        Slider { ...settings
        } > {
            sliderImages.map((image, index) => ( <
                div key = {
                    index
                } >
                <
                img src = {
                    `https://salertop.shop/admin/${image}`
                }
                alt = {
                    `Slider ${index + 1}`
                }
                style = {
                    {
                        width: '100%',
                        height: 'auto',
                        marginBottom: "0"
                    }
                }
                /> </div>
            ))
        } </Slider>
    );
};

export default ImageSlider;