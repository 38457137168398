import React, {
    useEffect,
    useState
} from 'react';
import axios from 'axios';
import ImageSlider from './ImageSlider';
import {
    Link,
    useParams,
    useNavigate
} from 'react-router-dom';
import {
    setUserId,
    getUserId,
    clearUserId
} from './localStorageUtils';
import Categories from './Categories';
import Loader from './loader';
import './ProductGrid.css';

const username = 'flipkart';
const password = '20$tgbsv09u';

const FormData = require('form-data');

const CategoryProductsPage = () => {
    const {
        id
    } = useParams();
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const [userId, setUserIdState] = useState(null);


    const getAuthHeader = (username, password) => {
        const credentials = `${username}:${password}`;
        const encodedCredentials = btoa(credentials); // Encode credentials to Base64
        console.log('API Response:', username);
        console.log('API Response:', encodedCredentials);
        return `Basic ${encodedCredentials}`;
    };

    const truncateText = (text, maxLength) => {
        if (text.length > maxLength) {
            return text.slice(0, maxLength) + '';
        }
        return text;
    };

    const handleAddToCart = (products) => {
        const fetchData = async () => {
            console.log('Fetching data from API...');
            try {
              const storedUserId = getUserId();
                let data = new FormData();
                data.append('product_id', products);
                data.append('user_id', storedUserId);
                console.log("product_id", products);
                let config = {
                    method: 'post',
                    maxBodyLength: Infinity,
                    url: 'https://salertop.shop/admin/api/v1/add_to_cart',
                    headers: {
                        'Authorization': 'Basic ZmxpcGthcnQ6MjAkdGdic3YwOXU=',
                    },
                    data: data
                };

                axios.request(config)
                    .then((response) => {
                        console.log('slider data', response.data.message);
                        navigate(`/cart/`);
                        setLoading(false);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } catch (err) {
                console.error('API request error:', err.response ? err.response.data : err.message);
                setError(err);
                setLoading(false);
            }
        };

        fetchData();
    };

    useEffect(() => {
        const storedUserId = getUserId();
        setUserIdState(storedUserId);
        const fetchData = async () => {
            console.log('Fetching data from API...');
            const authHeader = getAuthHeader(username, password);
            console.log('Authorization Header:', authHeader);


            try {
                let data = new FormData();
                data.append('category_id', id);
                console.log("category_id", id);
                let config = {
                    method: 'post',
                    maxBodyLength: Infinity,
                    url: 'https://salertop.shop/admin/api/v1/category_product',
                    headers: {
                        'Authorization': 'Basic ZmxpcGthcnQ6MjAkdGdic3YwOXU=',
                    },
                    data: data
                };

                axios.request(config)
                    .then((response) => {
                        console.log('slider data', response.data.data.slider);
                        setProducts(response.data.data.product || []);
                        setLoading(false);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } catch (err) {
                console.error('API request error:', err.response ? err.response.data : err.message);
                setError(err);
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    if (loading) return <Loader /> ;
    if (error) return <p > Error loading data: {
        error.message
    } </p>;

    return (
        <div>
          <section>
            {/* Products Listing */}
            {products.length > 0 ? (
              <ul>
                <div className="product-grid">
                  {products.map(product => (
                    <li key={product.id} className="product-card">
                      <Link to={`/product/${product.id}`}>
                        <img
                          src={`${product.image}`}
                          alt={product.product_name}
                          className="product-image"
                        />
                        <h3 className="product-name">
                          {truncateText(product.product_name, 20)}
                        </h3>
                        <div className="product-rating">
                          {'★'.repeat(Math.floor(4))}
                          {'☆'.repeat(5 - Math.floor(4))}
                        </div>
                        <p className="product-price">
                          <span className="discount-off">
                            &nbsp;{product.off_per}% Off
                          </span>
                          &nbsp;&nbsp;
                          <span className="original-price">
                            ₹{product.cancle_price}
                          </span>
                          &nbsp;
                        </p>
                        <div className='assured'>
                          <span className="discount-price">
                            ₹{product.price}.00
                          </span>
                          <img
                            className='assured-image'
                            src="https://project543.createinfotech.com/assured.png"
                            alt=""
                          />
                        </div>
                      </Link>
                      <button
                        onClick={() => handleAddToCart(product.id)}
                        className="product-buy"
                      >
                        Buy Now
                      </button>
                    </li>
                  ))}
                </div>
              </ul>
            ) : (
              <div className='no-product-container'>
                <img
                  src="https://cdn.icon-icons.com/icons2/3423/PNG/512/empty_page_no_results_empty_emoji_document_page_icon_218639.png"
                  alt='empty'
                  className="empty-image"
                />
                {/* <p className="no-product-container">No product found...</p> */}
              </div>
            )}
          </section>
        </div>
      );
    };
    
    export default CategoryProductsPage;