// src/components/CartItem.js
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Button
} from '@mui/material';
import {
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@mui/material';
import React, {
    useEffect,
    useState
} from 'react';
import {
    HiOutlineSave
} from "react-icons/hi";
import {
    RiDeleteBinLine
} from "react-icons/ri";
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import {
    AiOutlineThunderbolt
} from "react-icons/ai";
import {
    setUserId,
    getUserId,
    clearUserId
} from './localStorageUtils';
import {
    Link
} from 'react-router-dom';
import axios from 'axios';

const CartItem = ({ item, onUpdateQuantity, onRemove }) => {
    const [openAddPopUp, setOpenAddPopUp] = useState(false);
    const [loading, setLoading] = useState(true);
    const [apiloading, apisetLoading] = useState(true);
    const [age, setAge] = useState('');
    const plus = 'https://project543.createinfotech.com' + '/assets/truckimage.png';

    const [quantity, setQuantity] = useState(item.qty);
  const handleQuantityChange = (e) => {
    const newQuantity = parseInt(e.target.value) || 0; // Ensure valid number
    setQuantity(newQuantity); // Update local state
    // onUpdateQuantity(item.id, newQuantity); // Call the parent function
  };

  useEffect(() => {
    const updateQuantityAPI = async () => {
        try {
            const storedUserId = getUserId();
            let data = new FormData();
            data.append('product_id', item.product_id);
            data.append('user_id', storedUserId);
            data.append('qty', quantity)

            let config = {
                method: 'post',
                url: 'https://salertop.shop/admin/api/v1/cart_qty_update',
                headers: {
                    'Authorization': 'Basic ZmxpcGthcnQ6MjAkdGdic3YwOXU=',
                },
                data: data
            };
            console.log('API request data id:',  item.product_id);
            console.log('API request data qq:',   quantity);   
            setLoading(true);
            const response = await axios.request(config);
            console.log('API request conform:',   response.data);
            // setCartItems(response.data.data || []);
            // setTotalPrice(response.data.total);
        } catch (err) {
            console.error('API request error:', err.response ? err.response.data : err.message);
            // setError(err);
        } finally {
            apisetLoading(false);
            setLoading(false);
        }
    };
  
    if (quantity > 0) { // Only call API if quantity is greater than 0
      updateQuantityAPI();
    }
  }, [quantity]);
  const formatDeliveryDate = (date) => {
    const options = { weekday: 'short', day: 'numeric', month: 'short' };
    return date.toLocaleDateString('en-US', options);
  };

  const increaseQuantity = () => {
    setQuantity(prev => Number(prev) + 1);
  setLoading(false);
    if (!apiloading) {
      window.location.reload();
    }
  };
  const decreaseQuantity = () => {setQuantity((prev) => Math.max(1, prev - 1)); if (!apiloading ) {
    window.location.reload();
  } }; 

    const handleChange = (event) => {
        setAge(event.target.value);
    };

    const currentDate = new Date();
    const deliveryDate = new Date(currentDate);
    deliveryDate.setDate(currentDate.getDate() + 3); // Add 5 days to current date
    const formattedDeliveryDate = formatDeliveryDate(deliveryDate);

    return (
        <>
            <div className="cart-item">
                <div style={{ marginBottom: '5px' }}>
                    <div className='d-flex'>
                        <div className='prodctimgdiv'>
                            <div>
                                <img 
                                    style={{ padding: '5px' }}
                                    className='product-image'
                                    src={'https://salertop.shop/admin/'+item.product.image} 
                                    alt={item.product.product_name}
                                />
                            </div>
                            <div style={styles.quantity}>
            <button className='button'  onClick={() => decreaseQuantity()}>-</button>
            <span>Qty: </span>
            <input
            type="number"
            value={quantity}
            onChange={() => handleQuantityChange}
            style={styles.input}
          />
                        <style jsx>{`
                /* Remove arrows in Webkit browsers */
                input[type="number"]::-webkit-outer-spin-button,
                input[type="number"]::-webkit-inner-spin-button {
                  -webkit-appearance: none;
                  margin: 0;
                }

                /* Remove arrows in Firefox */
                input[type="number"] {
                  -moz-appearance: textfield;
                }
              `}</style>
            <button className='button' onClick={() => increaseQuantity()}>+</button>
        </div>
                           
                        </div>
                        <div className='prod-content'>
                            <div className="cart-item-details">
                                <h3 className='cart-product-name'>{item.product.product_name}</h3>
                                <div className="product-rating">
                                    {'★'.repeat(Math.floor(4))}
                                    {'☆'.repeat(5 - Math.floor(4))}
                                    <span className='cart-item-gray'>(18805)</span>
                                </div>
                                <p className="price-info">
                                    <span className="discount">{item.product.off_per}%</span>
                                    <span className="original-price">{item.product.cancle_price}</span>
                                    <span className="current-price">₹{item.product.price}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='w-100 d-flex' style={{ gap: "5px", whiteSpace: "nowrap", alignItems: "center", marginBottom: "10px" }}>
                    <img 
                        src={plus} 
                        style={{ maxWidth: "20px", height: "20px", objectFit: "contain" }} 
                        alt="Express Delivery Icon"
                    />
                    <span style={{ fontSize: "13px" }}>
                        <span style={{ fontStyle: "italic", fontWeight: "600" }}>EXPRESS</span>
                        <span style={{ paddingLeft: '10px' }}>Delivery by {formattedDeliveryDate}</span>
                        <span style={{ borderRight: '2px solid #D5D7DB', marginLeft: "5px" }}></span>
                    </span>
                    <span style={{ color: '#717478', fontWeight: 'normal', textDecoration: 'line-through', paddingRight: '5px' }}>₹42</span>
                    <p style={{ fontSize: '13px', fontWeight: '600', margin: '0' }}>
                        <span style={{ color: '#008C00' }}>FREE Delivery</span>
                    </p>
                </div>
                <div style={{ display: "flex", justifyContent: "space-around", borderTop: "1px solid rgba(255,255,255,1)" }}>
                    <button className='btn-save-new'>
                        <HiOutlineSave className='btn-save-icon' /> Save For Later
                    </button>
                    <button className='btn-save-new' onClick={() => onRemove(item.id)}>
                        <RiDeleteBinLine className='btn-save-icon' /> Remove
                    </button>
                    <Link to="/Stepper/:totalPrice">
                        <button className='btn-save-new'>
                            <AiOutlineThunderbolt className='btn-save-icon' /> Buy This Now
                        </button>
                    </Link>
                </div>
            </div>

            <Dialog open={openAddPopUp}>
                <DialogTitle id="alert-dialog-title" className="sky_text">
                    Remove Item
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={() => setOpenAddPopUp(false)}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <span>Are you sure you want to remove this item?</span>
                        <div style={{ marginTop: "12px", display: "flex", justifyContent: "center" }}>
                            <button className='btn-save' style={{ border: "1px solid gray" }} onClick={() => onRemove(item.id)}>Remove</button>
                            <button className='btn-save' style={{ border: "1px solid gray" }} onClick={() => setOpenAddPopUp(false)}>Cancel</button>
                        </div>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </>
    );
};

const styles = {
    container: {
        display: 'flex',
        alignItems: 'center',
        border: '1px solid #ddd',
        borderRadius: '8px',
        padding: '10px',
        marginBottom: '10px',
        width: '100%',
        // maxWidth: '300px', // Adjust the max-width as needed
        boxSizing: 'border-box',
        flexDirection: 'row', // Default is row for larger screens
        '@media(max-width: 768px)': { // Tablet and mobile
            flexDirection: 'column',
            alignItems: 'flex-start',
        }
    },
    image: {
        width: '80px',
        height: '80px',
        borderRadius: '8px',
        objectFit: 'cover',
        marginRight: '10px',
        '@media(max-width: 768px)': { // Tablet and mobile
            marginRight: '0',
            marginBottom: '10px',
            width: '100%',
        }
    },
    details: {
        // flex: 1,
        width: '100%',
        '@media(max-width: 768px)': { // Tablet and mobile
            marginBottom: '10px',
        }
    },
    title: {
        fontSize: '16px',
        margin: '0',
        color: '#333',
    },
    price: {
        fontSize: '14px',
        color: '#333',
        margin: '5px 0',
    },
    strike: {
        textDecoration: 'line-through',
        marginRight: '5px',
        color: '#999',
    },
    size: {
        fontSize: '14px',
        margin: '5px 0',
        color: '#666',
    },
    quantity: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        '@media(max-width: 768px)': { // Tablet and mobile
            justifyContent: 'space-between',
        }
    },
    input: {
      width: '40px',
      textAlign: 'center',
      borderRadius: '4px',
      border: '1px solid #ccc',
      margin: '0 5px',
      // Remove arrows
      '-webkit-appearance': 'none',
      '-moz-appearance': 'textfield',
    },
    button: {
       backgroundColor: 'transparent !important',
        // background: '#ddd',
        border: 'none !important',
        borderRadius: '4px',
        width: '24px',
        height: '24px',
        fontSize: '16px',
        cursor: 'pointer',
    },
    deleteButton: {
      backgroundColor: 'transparent !important',
        // background: 'none',
        border: 'none !important',
        fontSize: '16px',
        cursor: 'pointer',
        color: '#FFFFFF',
        marginLeft: '70%',
        '@media(max-width: 768px)': { // Tablet and mobile
            marginLeft: '0',
            alignSelf: 'flex-start',
        }
    },
  }

export default CartItem;