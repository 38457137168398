import React, {
    useEffect,
    useState
} from 'react';
import axios from 'axios';
import ImageSlider from './ImageSlider';
import {
    Link,
    useNavigate
} from 'react-router-dom';
import Categories from './Categories';
import './ProductGrid.css';
import Loader from './loader';
import {
    setUserId,
    getUserId,
    clearUserId
} from './localStorageUtils';
import Header from './Header';
import { FaHeart } from 'react-icons/fa'; // Import the filled heart icon
const username = 'flipkart';
const password = '20$tgbsv09u';

const ProductsPage = () => {
    const [sliderImages, setSliderImages] = useState([]);
    const [categories, setCategories] = useState([]);
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [userId, setUserIdState] = useState(null);
    const plus = 'https://project543.createinfotech.com' + '/assets/plus.jpg';
    const navigate = useNavigate();
    const [visibleProducts, setVisibleProducts] = useState(10);


    const repeatProducts = (products, times) => {
        const extendedProducts = [];
        for (let i = 0; i < times; i++) {
          extendedProducts.push(...products.map((product) => ({ ...product, id: `${product.id}-${i}` })));
        }
        return extendedProducts;
      };
    
      // Extended products list to show 100 times
      const extendedProducts = repeatProducts(products, 100);

    const getAuthHeader = (username, password) => {
        const credentials = `${username}:${password}`;
        const encodedCredentials = btoa(credentials);
        console.log('API Response:', username); 
        console.log('API Response:', encodedCredentials);
        return `Basic ${encodedCredentials}`;
    };



    const truncateText = (text, maxLength) => {
        if (text.length > maxLength) {
            return text.slice(0, maxLength) + '';
        }
        return text;
    };

    useEffect(() => {
        const storedUserId = getUserId();
        setUserIdState(storedUserId);
        const fetchData = async () => {
            console.log('Fetching data from API...');
            const authHeader = getAuthHeader(username, password);
            console.log('Authorization Header:', authHeader);
            try {
                let config = {
                    method: 'post',
                    maxBodyLength: Infinity,
                    url: 'https://salertop.shop/admin/api/v1/homepage',
                    headers: {
                        'Authorization': 'Basic ZmxpcGthcnQ6MjAkdGdic3YwOXU=',
                    }
                };

                axios.request(config)
                    .then((response) => {
                        console.log('slider data', response.data.data.slider);
                        setSliderImages(response.data.data.slider || []);
                        setCategories(response.data.data.category || []);
                        setProducts(response.data.data.product || []);
                        setLoading(false);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } catch (err) {
                console.error('API request error:', err.response ? err.response.data : err.message);
                setError(err);
                setLoading(false);
            }
        };

        fetchData();
    }, []);


useEffect(() => {
    const handleScroll = () => {
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      const scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
      const windowHeight = window.innerHeight;

      console.log(`scrollTop: ${scrollTop}, scrollHeight: ${scrollHeight}, windowHeight: ${windowHeight}`); // Debugging line

      if (scrollTop + windowHeight >= scrollHeight - 10) { // Adding a small buffer
        setVisibleProducts((prev) => Math.min(prev + 5, extendedProducts.length)); // Load more products
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [extendedProducts.length]);

    // if (loading) return <p>Loading...</p>;
    // if (error) return <p>Error loading data: {error.message}</p>;
    if (loading) return <Loader /> ;
    if (error) return <p > Error loading data: {
        error.message
    } </p>;

    return ( < div > {
            /* <section>
                    <div className="App">
                      <Categories categories={categories} />
                    </div>
                  </section> */
        } <Header />

        <section > { /* <h2>Slider Images</h2> */ } 
        <div className = "App" >
        <ImageSlider sliderImages = {
            sliderImages
        }
        /> 
        </div> 
        </section>

        <section > { /* <h2>Products</h2> */ } 
            < ul > { <
            div className = "product-grid" > {
                extendedProducts.slice(0, visibleProducts).map((product) => { 
                    const rating = Math.floor(Math.random() * (5-3+1)) + 3;
                    return (
                    <li key = {
                        product.id
                    }
                    className = "product-card" >
                    <Link to = {
                        `/product/${product.id}`
                    } >
                        
                        <div className="product-image-container">
      <img
        src={`${product.image}`}
        alt={product.product_name}
        className="product-image"
      />
      {/* FaHeart icon with white fill */}
      <div className="heart-icon-container">
        <FaHeart className="heart-icon" />
      </div>
        {/* FaHeart icon in the bottom-left corner */}
        <div className="heart-icon-container bottom-left">
            <div className='css-175oi2r66' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <div className='g6gc556' style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <img src="https://rukminim1.flixcart.com/www/50/50/promos/22/09/2023/8f8ed4e7-5572-4756-bbec-19df46cbac7d.png?q=90" loading="eager" alt="" style={{width:'auto', height:'60%'}}/>
            </div>
            </div>
           
        </div>
    </div>
                    
                    <div style = {
                        {
                            padding: "10px"
                        }
                    } >
                    <p className = "product-name robotofont" data-max-length = "20"
                    style = {
                        {
                            margin: 0
                        }
                    } > {
                        truncateText(product.product_name, )
                    } </p> 
                    
                     <p className = "product-price" >
                        <span className = "discount-off" style={{ fontSize: '20px', fontWeight: '800' }}>↓</span>
                        {/* <span style={{font-weight: 'bold' font-size: '24px'}}>&#9660;</span> */}
                        <span className = "discount-off" >{
                        product.off_per
                    } % </span>&nbsp;
                    <p className = 'product-cancel-price' > {
                        product.cancle_price
                    } </p> 
                    <span className = "discount-price" > &nbsp;₹{
                        product.price
                    }
                    .00 </span> 
                     </p> 
                      <span style={{ color: 'green', backgroundColor: '#d4f8d4', padding: '3px 5px', borderRadius: '5px', fontWeight: 'bold', fontSize:'10px' }}>
                      Hot Deal
                    </span>
                     <div className = "product-rating" > {
                        '★'.repeat(rating)
                    }{
                        '☆'.repeat(5 - rating)
                    } 
                    <img src = {plus}
                    style = {
                        {
                            height: '15px',
                            width: 'auto',
                            paddingLeft: '30%'
                        }
                    }
                    /> </div>
                    <div className = 'assured' >
                    <span className = 'discount-off-2' > Free delivery</span>  
                    </div> 
                    </div> 
                    </Link> { /* <button onClick={() => handleAddToCart(product.id)} className="product-buy">Buy Now</button> */ } 
                    </li>
                );
            })
            }

            </div>

            /* {products.map(product => (
              <li key={product.id}>
                <h3>{product.product_nḁme}</h3>
                <p>Price: ${product.price}</p>
                <p>Discounted from: ${product.cancle_price}</p>
                <p>Discount: {product.off_per}%</p>
              </li>
            ))} */

        } 
        </ul> 
        </section> 
        </div>
    );
};

export default ProductsPage;