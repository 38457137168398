import React from 'react';
import Review from './Review';
const generateReviewsData = (productName) => [{
        rating: 4.5,
        title: "Best product",
        product: productName,
        reviewText: "👌👌 Superb packaging",
        reviewer: "Alice Johnson",
        helpfulCount: 45,
        unhelpfulCount: 1,
        daysAgo: 3,
        verified: true,
    },
    {
        rating: 3,
        title: "Best product",
        product: productName,
        reviewText: "😙quality is good😙😙",
        reviewer: "Michael Brown",
        helpfulCount: 45,
        unhelpfulCount: 1,
        daysAgo: 3,
        verified: true,
    },
    {
        rating: 4.5,
        title: "Best product",
        product: productName,
        reviewText: " Superb packaging",
        reviewer: "Sarah Lee",
        helpfulCount: 45,
        unhelpfulCount: 1,
        daysAgo: 3,
        verified: true,
    },
    {
        rating: 4.5,
        title: "Best product",
        product: productName,
        reviewText: "👌👌 Superb packaging",
        reviewer: "David Kim",
        helpfulCount: 45,
        unhelpfulCount: 1,
        daysAgo: 3,
        verified: true,
    },
    {
        rating: 3,
        title: "Best product",
        product: productName,
        reviewText: "😙quality is good😙😙",
        reviewer: "Emma Davis",
        helpfulCount: 45,
        unhelpfulCount: 1,
        daysAgo: 3,
        verified: true,
    },
    {
        rating: 4.5,
        title: "Best product",
        product: productName,
        reviewText: " Superb packaging",
        reviewer: "Neha Tiwari",
        helpfulCount: 45,
        unhelpfulCount: 1,
        daysAgo: 3,
        verified: true,
    },
    // Add more reviews as needed
];
// const reviewsData = [
//     {
//         "name": "Alice Johnson",
//         "rating": 5,
//         "text": "I’m thrilled with this purchase! The product exceeded my expectations in terms of quality and performance. Customer service was also top-notch. I’ll definitely be coming back for more!",
//         "date": "2024-07-22"
//       },
//       {
//         "name": "Michael Brown",
//         "rating": 3,
//         "text": "The product is okay but not great. It works as described, but I had some issues with the setup process. I expected better for the price. It’s functional, but I’m not sure if I’d buy it again.",
//         "date": "2024-07-23"
//       },
//       {
//         "name": "Sarah Lee",
//         "rating": 4,
//         "text": "Good value for the money. The quality is quite high, though I had a minor issue with shipping. The product itself is fantastic and does exactly what I needed it to do.",
//         "date": "2024-07-24"
//       },
//       {
//         "name": "David Kim",
//         "rating": 2,
//         "text": "Disappointed with this purchase. The item arrived damaged, and while the replacement process was smooth, the product didn’t perform as advertised. I won’t be recommending this to others.",
//         "date": "2024-07-25"
//       },
//       {
//         "name": "Emma Davis",
//         "rating": 5,
//         "text": "Absolutely love this product! It’s everything I hoped for and more. The quality is outstanding, and it was delivered quickly. Highly recommend to anyone looking for something like this.",
//         "date": "2024-07-26"
//       },
//   // Add 8 more reviews here...
// ];

const ReviewsList = ({productName }) => {
    // <div className="reviews-list">
    //   <h2>Ratings & Reviews</h2>
    //   {reviewsData.map((review, index) => (
    //     <Review key={index} review={review} />
    //   ))}
    // </div>

    const reviewsData = generateReviewsData(productName);
return(
    <div style = {
        {
            boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 8px',
            marginBottom: "9px"
        }
    } >
    <div className = 'main-rateProduct' >
    <span style = {
        {
            fontSize: "15px",
            fontWeight: "600"
        }
    } > Ratings & Reviews </span> 
    <button className = 'rate-product-btn' >
    <span style = {
        {
            color: "rgba(40,116,240,1)",
            fontWeight: "600"
        }
    } > Rate Product </span> 
    </button> 
    </div> 
    < div className = 'rating-space' >
    <div className = "product-rating"
    style = {
        {
            justifyContent: "space-between"
        }
    } > {
        '★'.repeat(Math.floor(4))
    } {
        '☆'.repeat(5 - Math.floor(4))
    } </div> 
    <span style = {
        {
            fontSize: "0.9em",
            color: "gray"
        }
    } > 3903 ratings and 423 reviews </span> </div> 
    <div > 
    {reviewsData.map((review, index) => (
                    <Review key={index} review={review} />
                ))}
       </div> 
    </div>
);
}

export default ReviewsList;