// Loader.js
import React from 'react';

const Loader = () => {
    return ( <
        div className = "loader-container" >
        <
        div className = "loader" > </div> </div>
    );
};

export default Loader;