import React, {
    useEffect,
    useState
} from 'react';
import {
    Box,
    Button,
    MenuItem,
    Select
} from '@mui/material';
import {
    useNavigate
} from 'react-router-dom'; // Import useNavigate hook
import {
    getUserId
} from './localStorageUtils';
import axios from 'axios';
import CartItem from './CartItem';
import Orderitembaynow from './orderitembaynow';

const OrderSummaryPagebuynow = ({id}) => {
    const navigate = useNavigate(); // Initialize useNavigate hook
    const iphone = 'https://project543.createinfotech.com' + '/assets/iphone.jpg';
    const plus = 'https://project543.createinfotech.com' + '/assets/iphone.jpg';
    const [age, setAge] = useState('');
    const [product, setProduct] = useState(null);
    const [totalPrice, setTotalPrice] = useState(0);
    const [userId, setUserIdState] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const handleChange = (event) => {
        setAge(event.target.value);
    };
    useEffect(() => {
        const storedUserId = getUserId();
        setUserIdState(storedUserId);

        const fetchProduct = async () => {
            try {
                console.log("Fetching product with ID:", id);
                let data = new FormData();
                data.append('product_id', id);
        
                let config = {
                    method: 'post',
                    maxBodyLength: Infinity,
                    url: 'https://salertop.shop/admin/api/v1/single_product',
                    headers: {
                        'Authorization': 'Basic ZmxpcGthcnQ6MjAkdGdic3YwOXU=',
                    },
                    data: data,
                };
        
                const response = await axios.request(config);
                console.log("API response:", response);
                const productData = response.data.data.product;
                console.log("Setting product data:", productData);
                setProduct(productData);
                console.log("Setting product data: done", product);
                // setLoading(false);
            } catch (error) {
                setError('Error fetching product data');
                console.error(error);
            }
        };
        fetchProduct();
    }, [id]);
    const handleAddSummary = () => {
        navigate('/payment'); // Use navigate function for redirection
    };

    return ( <
        div style = {
            {
                paddingBottom: "75px"
            }
        } >
        <
        h5 > Deliver to: </h5> {
            loading ? ( <
                p > Loading... </p>
            ) : error ? ( <
                p > Error loading cart items. </p>
            ) : (
                <Orderitembaynow
                      key={product.id}
                       item={product}

                    />
    ) 
        }

        <
        div className = "cart-summary"
        style = {
            {
                paddingLeft: "15px",
                paddingRight: "15px"
            }
        } >
        <
        p style = {
            {
                fontSize: "15px",
                fontWeight: 500
            }
        } > Cart Summary </p> <
        div >
        <
        p > &nbsp; Price Details </p> <
        div className = "price-item" >
        <span > &nbsp; Price(1 item) </span> <
        span > ₹{
            totalPrice
        } </span> </div> <
        div className = "price-item" >
        <
        span > &nbsp; Delivery Charges </span> <
        span className = "free-delivery" > FREE Delivery </span> </div> <
        div className = "price-item total" >
        <span > &nbsp; Total Amount </span> <
        span > ₹{
            totalPrice
        } </span> </div>
         </div> 
        <div style = {
            {
                display: "flex",
                justifyContent: "end"
            }
        } >
        <
        Box sx = {
            {
                mt: 2,
                position: 'fixed',
                bottom: 0,
                left: 0,
                right: 0,
                width: '100%',
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
                backgroundColor: 'white',
                zIndex: 1000,
                display: 'flex',
                justifyContent: 'end',
                p: 2,
            }
        } >
        < Button
        // onClick={handleAddToCart}
        sx = {
            {
                height: 'fit-content',
                width: '10%',
                textTransform: 'none',
                color: 'black',
                mr: 4,
                width: "168px",
                height: "40px",
                font: 'normal',
                background: 'rgba(255, 195, 3, 1)',
                '&:hover': {
                    backgroundColor: 'rgba(255, 195, 3, 1)',
                },
            }
        } >
        Place Order</Button> </Box> </div> </div> </div>
    );
};

export default OrderSummaryPagebuynow;