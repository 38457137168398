// src/components/Cart.js
import React, {
  useEffect,
  useState
} from 'react';
import CartItem from './CartItem';
import axios from 'axios';
import {
  Link,
  useNavigate
} from 'react-router-dom';
import {
  getUserId
} from './localStorageUtils';
import {
  Box,
  Button,
  IconButton,
  Typography,
  Grid
} from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Cart = () => {
  const [cartItems, setCartItems] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [userId, setUserIdState] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const backArrow2 = 'https://project543.createinfotech.com' + '/assets/backArrow2.svg';

  useEffect(() => {
    const storedUserId = getUserId();
    setUserIdState(storedUserId);

    const fetchData = async () => {
      if (!storedUserId) return;

      try {
        let data = new FormData();
        data.append('user_id', storedUserId);

        let config = {
          method: 'post',
          url: 'https://salertop.shop/admin/api/v1/list_cart',
          headers: {
            'Authorization': 'Basic ZmxpcGthcnQ6MjAkdGdic3YwOXU=',
          },
          data: data
        };

        const response = await axios.request(config);
        setCartItems(response.data.data || []);
        setTotalPrice(response.data.total);
      } catch (err) {
        console.error('API request error:', err.response ? err.response.data : err.message);
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const removeItem = async (id) => {
    const storedUserId = getUserId();
    if (!userId) return;

    try {
      let data = new FormData();
      data.append('product_id', id);
      data.append('user_id', storedUserId);

      let config = {
        method: 'post',
        url: 'https://salertop.shop/admin/api/v1/delete_to_cart',
        headers: {
          'Authorization': 'Basic ZmxpcGthcnQ6MjAkdGdic3YwOXU=',
        },
        data: data
      };

      await axios.request(config);
      toast.success('Item removed successfully!', {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: { backgroundColor: 'black', color: 'white' },
      });
      setCartItems(cartItems.filter(item => item.product.id !== id));
      setTotalPrice(prevTotalPrice => prevTotalPrice - (cartItems.find(item => item.product.id === id)?.product.price || 0));
    } catch (err) {
      console.error('API request error:', err.response ? err.response.data : err.message);
      setError(err);
    }
  };

  const handleAddToCart = () => {
    navigate(`/Stepper/${totalPrice}`);
  };

  return (
    <div className="cart" style={{ paddingBottom: "75px" }}>
      <ToastContainer />

      {/* Cart Header */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          padding: '8px 16px',
          backgroundColor: '#f5f5f5',
          borderBottom: '1px solid #ddd',
        }}
      >
        <Link to="/" style={{ textDecoration: 'none', color: 'black' }}>
          <IconButton edge="start" aria-label="back">
            <img src={backArrow2} width={30} height={20} paddingRight={30} alt="Previous Step" />
          </IconButton>
        </Link>
        <Typography
          variant="h5"
          component="div"
          sx={{
            flexGrow: 1,
            fontSize: {
              xs: '1.25rem', // font size for extra small screens
              sm: '1.5rem',  // font size for small screens
              md: '1.75rem', // font size for medium screens
            },
          }}
        >
          My Cart
        </Typography>
      </Box>

      {/* Cart Items */}
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        null
      ) : cartItems.length > 0 ? (
        cartItems.map(item => (
          item.product && (
            <CartItem
              key={item.product.id}
              item={item}
              onRemove={() => removeItem(item.product.id)}
            />
          )
        ))
      ) : (
        <p>Your cart is empty.</p>
      )}

      {/* Cart Summary */}
      <div className="cart-summary" style={{ paddingLeft: "15px", paddingRight: "15px" }}>
        <Box>
          <Typography variant="h6" gutterBottom>
            Price Details
          </Typography>
          <div>
            <div className="price-item">
              <span>&nbsp; Price (1 item)</span>
              <span>₹{totalPrice}</span>
            </div>
            <div className="price-item">
              <span>&nbsp; Discount</span>
              <span>-₹0</span>
            </div>
            <div className="price-item">
              <span>&nbsp; Delivery Charges</span>
              <span className="free-delivery">FREE Delivery</span>
            </div>
            <div className="price-item total">
              <span>&nbsp; Total Amount</span>
              <span>₹{totalPrice}</span>
            </div>
          </div>
        </Box>

        {/* Place Order Button */}
        <div style={{ display: "flex", justifyContent: "end" }}>
          <Box
            sx={{
              mt: 2,
              position: 'fixed',
              bottom: 0,
              left: 0,
              right: 0,
              width: '100%',
              boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
              backgroundColor: 'white',
              zIndex: 1000,
              display: 'flex',
              justifyContent: 'end',
              p: 2,
            }}
          >
            <Button
              onClick={handleAddToCart}
              sx={{
                height: 'fit-content',
                width: '10%',
                textTransform: 'none',
                color: 'black',
                mr: 4,
                width: "168px",
                height: "40px",
                font: 'normal',
                background: 'rgba(255, 195, 3, 1)',
                '&:hover': {
                  backgroundColor: 'rgba(255, 195, 3, 1)',
                },
              }}
            >
              Place Order
            </Button>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default Cart;