import React, {
    useEffect,
    useState
} from 'react';
import {
    Stepper,
    Step,
    StepLabel,
    Button,
    TextField,
    MenuItem,
    Box,
    Container,
    IconButton,
    Typography
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
    Link
} from 'react-router-dom';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import {
    FaHome,
    FaBriefcase,
    FaBuilding
} from 'react-icons/fa';
import {
    getUserId,
} from './localStorageUtils';
import {
    IoMdHome
} from 'react-icons/io';
import OrderSummaryPage from './OrderSummaryPage';
import PaymentPage from './PaymentPage';
import axios from 'axios';
// const OrderSummary = ({ cartItems, totalPrice, handleNextStep }) => {
//     return (
//         <Box>
//             <Typography variant="h6">Order Summary</Typography>
//             <Box>
//                 {cartItems.length > 0 ? (
//                     cartItems.map((item) => (
//                         <div className="cart-summary" style={{ paddingLeft: "15px", paddingRight: "15px" }}>
//                             <h3>&nbsp;Cart Summary</h3>
//                             <div>
//                                 <h2>&nbsp;Price Details</h2>
//                                 <div className="price-item">
//                                     <span>&nbsp;Price (1 item)</span>
//                                     <span>₹{totalPrice}</span>
//                                 </div>
//                                 <div className="price-item">
//                                     <span>&nbsp;Delivery Charges</span>
//                                     <span className="free-delivery">FREE Delivery</span>
//                                 </div>
//                                 <div className="price-item total">
//                                     <span>&nbsp;Total Amount</span>
//                                     <span>₹{totalPrice}</span>
//                                 </div>
//                             </div>
//                             <div style={{ display: "flex", justifyContent: "end" }}>
//                                 <button className='checkout' >Place Order </button>
//                             </div>
//                         </div>
//                     ))
//                 ) : (
//                     <Typography>No items in the cart.</Typography>
//                 )}
//             </Box>
//             <Box display="flex" justifyContent="space-between" marginTop="24px">
//                 <Typography variant="h6">Total</Typography>
//                 <Typography variant="h6">₹{totalPrice}</Typography>
//             </Box>
//             <Button
//                 variant="contained"
//                 color="primary"
//                 onClick={handleNextStep}
//                 style={{ marginTop: '16px' }}
//                 fullWidth
//             >
//                 Proceed to Payment
//             </Button>
//         </Box>
//     );
// };
const addressIcon = 'https://project543.createinfotech.com' + '/assets/address-icon.svg';
const OrderSummaryIcon = 'https://project543.createinfotech.com' + '/assets/ordersummaryIcon.png';
const paymentIcon = 'https://project543.createinfotech.com' + '/assets/paymentIcon.svg';
const backArrow2 = 'https://project543.createinfotech.com' + '/assets/backArrow2.svg';

const steps = [{
    label: 'Add delivery address',
    icon: addressIcon
},
{
    label: 'Order Summary',
    icon: OrderSummaryIcon
},
{
    label: 'Payment',
    icon: paymentIcon
},
];
const StepperForm = () => {
    const [activeStep, setActiveStep] = useState(0);
    const [formData, setFormData] = useState({
        fullName: '',
        mobileNumber: '',
        pincode: '',
        city: '',
        state: '',
        houseNumber: '',
        roadName: '',
        addphoneNumber: '',
        addlandMark: ''
    });
    const [selected, setSelected] = useState("");
    const [errors, setErrors] = useState({});
    const cartItems = []; // Mock cart items array
    const totalPrices = 0; // Mock total price
    const [showAddPhone, setShowAddPhone] = useState(false);
    const [showLandMark, setShowLandMark] = useState(false);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [totalPrice, setTotalPrice] = useState(0);
    const states = [
        'Andhra Pradesh', 'Arunachal Pradesh', 'Assam', 'Bihar', 'Chhattisgarh',
        'Goa', 'Gujarat', 'Haryana', 'Himachal Pradesh', 'Jammu and Kashmir',
        'Jharkhand', 'Karnataka', 'Kerala', 'Madhya Pradesh', 'Maharashtra',
        'Manipur', 'Meghalaya', 'Mizoram', 'Nagaland', 'Odisha', 'Punjab',
        'Rajasthan', 'Sikkim', 'Tamil Nadu', 'Telangana', 'Tripura', 'Uttar Pradesh',
        'Uttarakhand', 'West Bengal'
    ];
    const [label, setLabel] = useState('Add Address Delivery')
    // const handleNext = () => {
    //     if (validateForm()) {
    //         setActiveStep((prevActiveStep) => prevActiveStep + 1);
    //         if (activeStep == 1) {
    //             setLabel('order')
    //         } else if (activeStep == 2) {
    //             setLabel('Payment')
    //         }
    //     }
    // };

    const handleNextStep = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    useEffect(() => {
        // const storedUserId = getUserId();
        // setUserIdState(storedUserId);

        const fetchData = async () => {
            // if (!storedUserId) return;

            try {
                const storedUserId = getUserId();
                let data = new FormData();
                data.append('user_id', storedUserId);

                let config = {
                    method: 'post',
                    url: 'https://salertop.shop/admin/api/v1/list_cart',
                    headers: {
                        'Authorization': 'Basic ZmxpcGthcnQ6MjAkdGdic3YwOXU=',
                    },
                    data: data
                };

                const response = await axios.request(config);
                // setCartItems(response.data.data || []);
                setTotalPrice(response.data.total);
            } catch (err) {
                console.error('API request error:', err.response ? err.response.data : err.message);
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    // const handleChange = (e) => {
    //     const { name, value } = e.target;
    //     setFormData({
    //         ...formData,
    //         [name]: value
    //     });
    // };

    // const validateForm = () => {
    //     let tempErrors = {};
    //     if (!formData.fullName) tempErrors.fullName = 'Full Name is required';
    //     if (!formData.mobileNumber) tempErrors.mobileNumber = 'Mobile Number is required';
    //     if (!formData.pincode) tempErrors.pincode = 'Pincode is required';
    //     if (!formData.city) tempErrors.city = 'City is required';
    //     if (!formData.state) tempErrors.state = 'State is required';
    //     if (!formData.houseNumber) tempErrors.houseNumber = 'House No., Building Name is required';
    //     if (!formData.roadName) tempErrors.roadName = 'Road name, Area, Colony is required';

    //     setErrors(tempErrors);
    //     return Object.keys(tempErrors).length === 0;
    // };
    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handlePreviousStep = () => {
        setActiveStep((prev) => Math.max(prev - 1, 0)); // Ensure it doesn't go below 0
    };

    const validateStep = () => {
        // Perform validation here and return true/false
        // Update the `errors` state if there are errors
        return true; // return false if there are validation errors
    };


    const handleUseLocation = () => {
        // Handle location logic here
    };
    // const handleUseLocation = () => {
    //     if (navigator.geolocation) {
    //         navigator.geolocation.getCurrentPosition((position) => {
    //             const { latitude, longitude } = position.coords;
    //         });
    //     } else {
    //         alert('Geolocation is not supported by this browser.');
    //     }
    // };

    const renderStepContent = (step, address, handleNextStep, handlePreviousStep) => {
        switch (step) {
            case 0:
                return (
                    <Box sx={
                        {
                            padding: 2
                        }
                    } >
                        < TextField size='small'
                            name="fullName"
                            label="Full Name (Required)"
                            value={
                                formData.fullName
                            }
                            onChange={
                                handleChange
                            }
                            error={!!errors.fullName
                            }
                            helperText={
                                errors.fullName
                            }
                            fullWidth required margin="normal"
                            InputLabelProps={
                                {
                                    style: {
                                        color: 'gray',
                                        fontSize: '15px',
                                    },
                                }
                            }
                        />

                        <TextField size='small'
                            name="mobileNumber"
                            label="Phone Number (Required)"
                            value={
                                formData.mobileNumber
                            }
                            onChange={
                                handleChange
                            }
                            error={!!errors.mobileNumber
                            }
                            helperText={
                                errors.mobileNumber
                            }
                            fullWidth required margin="normal"
                            InputLabelProps={
                                {
                                    style: {
                                        color: 'gray',
                                        fontSize: '15px',
                                    },
                                }
                            }
                        /> {
                            showAddPhone && (

                                <
                                    TextField size='small'
                                    label="Add Alternate Phone Number "
                                    name="addphoneNumber"
                                    value={
                                        formData.addphoneNumber
                                    }
                                    onChange={
                                        handleChange
                                    }
                                    error={!!errors.addphoneNumber
                                    }
                                    helperText={
                                        errors.addphoneNumber
                                    }
                                    fullWidth margin="normal"
                                    InputLabelProps={
                                        {
                                            style: {
                                                color: 'gray',
                                                fontSize: '15px',
                                            },
                                        }
                                    }
                                />
                            )
                        } {
                            !showAddPhone && (
                                <label htmlFor="addPhone"
                                    onClick={
                                        () => setShowAddPhone(true)
                                    }
                                    style={
                                        {
                                            fontSize: 'small',
                                            color: '#1976d2',
                                            cursor: "pointer"
                                        }
                                    }
                                    className="fs-6"

                                >
                                    +Add Alternate Phone Number </label>

                            )
                        }
                        <Box sx={
                            {
                                width: "100%",
                                display: "flex",
                                gap: "15px"
                            }
                        } >
                            < TextField name="pincode"
                                label="Pincode (Required)"
                                size='small'
                                value={
                                    formData.pincode
                                }
                                onChange={
                                    handleChange
                                }
                                error={!!errors.pincode
                                }
                                helperText={
                                    errors.pincode
                                }
                                sx={
                                    {
                                        width: "50%"
                                    }
                                }
                                required margin="normal"
                                InputLabelProps={
                                    {
                                        style: {
                                            color: 'gray',
                                            fontSize: '15px',
                                        },
                                    }
                                }
                            />
                            <Button variant="contained"
                                color="primary"
                                onClick={
                                    handleUseLocation
                                }
                                startIcon={< MyLocationIcon sx={
                                    {
                                        fontSize: "10px"
                                    }
                                }
                                />}
                                sx={
                                    {
                                        height: 'fit-content',
                                        marginTop: '16px',
                                        width: "60%",
                                        textTransform: 'none',
                                        font: "normal"
                                    }
                                } >
                                Use my location
                            </Button>
                        </Box>

                        <Box sx={
                            {
                                width: "100%",
                                display: "flex",
                                gap: "15px"
                            }
                        } >
                            <TextField
                                size='small'
                                name="state"
                                label="State (Required)"
                                value={
                                    formData.state
                                }
                                onChange={
                                    handleChange
                                }
                                error={!!errors.state
                                }
                                helperText={
                                    errors.state
                                }
                                sx={
                                    {
                                        width: "50%"
                                    }
                                }
                                required
                                margin="normal"
                                InputLabelProps={
                                    {
                                        style: {
                                            color: 'gray',
                                            fontSize: '15px',
                                        },
                                    }
                                } >
                                {
                                    states.map((state) => (
                                        < MenuItem key={
                                            state
                                        }
                                            value={
                                                state
                                            } > {
                                                state
                                            } </MenuItem>
                                    ))
                                } </TextField>

                            <TextField
                                name="city"
                                label="City"
                                size='small'
                                value={
                                    formData.city
                                }
                                onChange={
                                    handleChange
                                }
                                error={!!errors.city
                                }
                                helperText={
                                    errors.city
                                }
                                sx={
                                    {
                                        width: "50%"
                                    }
                                }
                                required
                                margin="normal"
                                InputLabelProps={
                                    {
                                        style: {
                                            color: 'gray',
                                            fontSize: '15px',
                                        },
                                    }
                                }
                            />

                        </Box>

                        <TextField
                            size='small'
                            name="houseNumber"
                            label="House No., Building Name"
                            value={
                                formData.houseNumber
                            }
                            onChange={
                                handleChange
                            }
                            error={!!errors.houseNumber
                            }
                            helperText={
                                errors.houseNumber
                            }
                            fullWidth
                            required
                            margin="normal"
                            InputLabelProps={
                                {
                                    style: {
                                        color: 'gray',
                                        fontSize: '15px',
                                    },
                                }
                            }
                        />
                        <TextField
                            size='small'
                            name="roadName"
                            label="Road name, Area, Colony"
                            value={
                                formData.roadName
                            }
                            onChange={
                                handleChange
                            }
                            error={!!errors.roadName
                            }
                            helperText={
                                errors.roadName
                            }
                            fullWidth
                            required
                            margin="normal"
                            InputLabelProps={
                                {
                                    style: {
                                        color: 'gray',
                                        fontSize: '15px',
                                    },
                                }
                            }
                        />
                        {
                            showLandMark && (
                                <TextField size='small'
                                    name="addlandMark"
                                    label="Add Nearby Famous Shop/Mall/Landmark"
                                    value={
                                        formData.addlandMark
                                    }
                                    onChange={
                                        handleChange
                                    }
                                    error={!!errors.addlandMark
                                    }
                                    helperText={
                                        errors.addlandMark
                                    }
                                    fullWidth margin="normal"
                                    InputLabelProps={
                                        {
                                            style: {
                                                color: 'gray',
                                                fontSize: '15px',
                                            },
                                        }
                                    }
                                />
                            )
                        } {
                            !showLandMark && (
                                <label htmlFor="addPhone"
                                    onClick={
                                        () => setShowLandMark(true)
                                    }
                                    style={
                                        {
                                            fontSize: 'small',
                                            color: '#1976d2',
                                            cursor: "pointer",
                                            marginBottom: "15px"
                                        }
                                    }
                                    className="fs-6" >
                                    +Add Nearby Famous Shop / Mall / Landmark
                                </label>

                            )
                        }
                        <div style={
                            {
                                marginTop: "15px",
                                fontSize: "13px",
                                color: "gray",
                                marginBottom: "15px"
                            }
                        } >
                            <span > Type of Address </span>
                        </div>
                        < Box sx={
                            {
                                display: 'flex',
                                alignItems: 'center',
                                gap: 2
                            }
                        } >
                            <Button

                                variant='outlined'
                                startIcon={< IoMdHome />
                                }
                                sx={
                                    {
                                        color: selected === 'home' ? '#1976d2' : 'gray',
                                        border: `1px solid ${selected === 'home' ? '#1976d2' : 'gray'}`,
                                        borderRadius: '25px',
                                        "&:hover": {
                                            backgroundColor: selected === 'home' ? 'white' : 'transparent',
                                            borderColor: selected === 'home' ? '#1976d2' : 'gray',
                                        },
                                    }
                                }
                                onClick={
                                    () => setSelected('home')
                                } >
                                Home </Button>
                            <Button

                                variant='outlined'
                                startIcon={< FaBuilding />
                                }
                                sx={
                                    {
                                        color: selected === 'work' ? '#1976d2' : 'gray',
                                        border: `1px solid ${selected === 'work' ? '#1976d2' : 'gray'}`,
                                        borderRadius: '25px',
                                        "&:hover": {
                                            backgroundColor: selected === 'work' ? 'white' : 'transparent',
                                            borderColor: selected === 'work' ? '#1976d2' : 'gray',
                                        },
                                    }
                                }
                                onClick={
                                    () => setSelected('work')
                                } >
                                Work </Button>
                        </Box>
                    </Box>
                );
            case 1:
                return (<
                    OrderSummaryPage address={
                        address
                    }
                    onNext={
                        handleNextStep
                    }
                    onPrevious={
                        handlePreviousStep
                    }
                />
                );
            case 2:
                return <PaymentPage />
            default:
                return 'Unknown step';
        }
    };
    return (
        // <Container sx={{ position: 'relative', bgcolor: 'white', minWidth: "100%", padding: 0 }}>
        <>
            <div style={
                {
                    marginBottom: '9px',
                    display: 'flex',
                    alignItems: 'center',
                    padding: '10px 20px'
                }
            } >
                <div > {
                    activeStep > 0 && (
                        <div onClick={
                            handlePreviousStep
                        }
                            style={
                                {
                                    cursor: 'pointer',
                                    marginRight: '20px',
                                    display: 'flex',
                                    alignItems: 'center'
                                }
                            } >
                            < img src={
                                backArrow2
                            }
                                width={
                                    20
                                }
                                height={
                                    20
                                }
                                alt="Previous Step" />

                            {steps.map((step, index) => (
                                < div key={
                                    step.label
                                }
                                    style={
                                        {
                                            display: activeStep === index ? 'flex' : 'none',
                                            alignItems: 'center',
                                            flexDirection: 'column',
                                            textAlign: 'center'
                                        }
                                    } >

                                    <StepLabel > &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{
                                        step.label
                                    } </StepLabel>  </div>
                            ))
                            }
                        </div>
                    )
                }

                </div>
            </div>
            < div style={
                {
                    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 8px',
                    flex: 1,
                    display: 'flex',
                    justifyContent: 'center',
                    padding: '0 20px'
                }
            } > {
                    steps.map((step, index) => (
                        < div key={
                            step.label
                        }
                            style={
                                {
                                    display: activeStep === index ? 'flex' : 'none',
                                    alignItems: 'center',
                                    flexDirection: 'column',
                                    textAlign: 'center'
                                }
                            } >
                            < Step >
                                {
                                    activeStep === index && < img src={
                                        step.icon
                                    }
                                        className="fixed-size"
                                        alt={
                                            step.label
                                        }
                                    />} </Step> </div>
                    ))
                } </div>
            <Box sx={
                {
                    display: 'flex',
                    flexDirection: 'column'
                }
            } >
                <Box sx={
                    {
                        flex: '1 1 auto'
                    }
                } > {
                        renderStepContent(activeStep)
                    } </Box>
                < Box sx={
                    {
                        display: 'flex',
                        flexDirection: 'row',
                        pt: 2
                    }
                } >
                    <Box sx={
                        {
                            flex: '1 1 auto'
                        }
                    }
                    /> {
                        activeStep === 0 && (
                            <>
                                <Button variant="contained"
                                    color="primary"
                                    sx={
                                        {
                                            width: '100%',
                                            backgroundColor: '#ff5800',
                                            textAlign: 'center',
                                            borderRadius: '0px',
                                            padding: '12px 24px',
                                            '&:hover': {
                                                backgroundColor: '#ff5800'
                                            },
                                            justifyContent: 'center',
                                            textTransform: "none",
                                            fontSize: "15px"
                                        }
                                    }
                                    onClick={
                                        () => {
                                            if (validateStep()) handleNextStep();
                                        }
                                    } >
                                    Save Address
                                </Button>
                            </>
                        )
                    } {
                        activeStep > 0 && activeStep < 2 && (
                            <Box sx={
                                {
                                    mt: 2,
                                    position: 'fixed',
                                    bottom: 0,
                                    left: 0,
                                    right: 0,
                                    width: '100%',
                                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
                                    backgroundColor: 'white',
                                    zIndex: 1000,
                                    display: 'flex',
                                    justifyContent: 'end',
                                    p: 2,
                                }
                            } >
                                <Button onClick={
                                    handleNextStep
                                }
                                    sx={
                                        {
                                            height: 'fit-content',
                                            width: '10%',
                                            textTransform: 'none',
                                            color: 'black',
                                            mr: 4,
                                            width: "168px",
                                            height: "40px",
                                            font: 'normal',
                                            background: 'rgba(255, 195, 3, 1)',
                                            '&:hover': {
                                                backgroundColor: 'rgba(255, 195, 3, 1)',
                                            },
                                        }
                                    } >
                                    Continue
                                </Button>
                            </Box>
                        )
                    }
                </Box>
            </Box>
        </>
        // </Container>
    );
};

export default StepperForm;