// src/components/Header.js
import React, {
  useEffect,
  useState
} from 'react';
import {
    FaSearch,
    FaShoppingCart,
    FaBars
} from 'react-icons/fa';
import {
    Link,
    useNavigate
} from 'react-router-dom';
import {
    PiShoppingCart
} from "react-icons/pi";
import {
  setUserId,
  getUserId,
  clearUserId
} from './localStorageUtils';
import {
    FaRegCircleUser
} from "react-icons/fa6";
import {
    IoSearchSharp
} from "react-icons/io5";
import axios from 'axios';
const Header = () => {
    const navigate = useNavigate();
    const flipcart = 'https://project543.createinfotech.com' + '/assets/flipcart.png';
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [cartQuantity, setcartQuantity] = useState(0);

    const handleAddToCart = () => {
        navigate(`/cart/`);
    };

    useEffect(() => {
      // const storedUserId = getUserId();
      // setUserIdState(storedUserId);

      const fetchData = async () => {
          // if (!storedUserId) return;

          try {
            const storedUserId = getUserId();
              let data = new FormData();
              data.append('user_id', storedUserId);

              let config = {
                  method: 'post',
                  url: 'https://salertop.shop/admin/api/v1/list_cart',
                  headers: {
                      'Authorization': 'Basic ZmxpcGthcnQ6MjAkdGdic3YwOXU=',
                  },
                  data: data
              };

              const response = await axios.request(config);
              setcartQuantity(response.data.qty);
              // setCartItems(response.data.data || []);
              // setTotalPrice(response.data.total);
          } catch (err) {
              console.error('API request error:', err.response ? err.response.data : err.message);
              setError(err);
          } finally {
              setLoading(false);
          }
      };

      fetchData();
  }, []);

    return (
        <header className="header">
          {/* Top Header Section */}
          <div className="header-top">
            <div className="logo">
              {/* Hamburger Menu */}
              <div className="hamburger-menu">
                <FaBars />
              </div>
              {/* Logo */}
              <Link to={`/`}>
                <img src={flipcart} alt="Logo" style={{transform: 'translateY(+10%)'}} />
              </Link>
            </div>
    
            {/* Header Icons */}
            <div className="header-icons">
              {/* Login Icon */}
              <div className="icon-item">
                <FaRegCircleUser size={20} style={{ display: 'flex', alignItems: 'center' }} />
                &nbsp;Login
              </div>
              {/* Cart Icon */}
              <button className="icon-button-item" onClick={handleAddToCart}>
                <PiShoppingCart />
                {cartQuantity > 0 && <span className="cart-quantity">{cartQuantity}</span>}
              </button>
            </div>
          </div>
    
          {/* Search Bar Section */}
          <div className="search-bar">
            <IoSearchSharp className="search-icon" />
            <input
              type="text"
              placeholder="Search for Products, Brands and More"
            />
          </div>
        </header>
      );
    }
    
    export default Header;