export const setUserId = (userId) => {
    if (userId == null) {
        const currentTime = new Date().getTime();
        const setuserId = `${currentTime}`;
        localStorage.setItem('userId', setuserId);
    } else {
        console.warn('User ID is null or undefined and cannot be set.');
    }
};

export const getUserId = () => {
    return localStorage.getItem('userId');
};

export const clearUserId = () => {
    localStorage.removeItem('userId');
};