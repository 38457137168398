import React, {
    useState,
    useEffect
} from 'react';
import {
    BrowserRouter as Router,
    Route,
    Routes
} from 'react-router-dom';
import ProductPage from './components/ProductPage';
import ProductGrid from './components/ProductGrid';
import Stepper from './components/Stepper';
import CategoryProductsPage from './components/categoryPage';
import Stepperbaynow from './components/Stepperbaynow';
import Header from './components/Header';
import Cart from './components/cartpage';
import {
    setUserId,
    getUserId,
    clearUserId
} from './components/localStorageUtils';
import PaymentPage from './components/PaymentPage';

function App() {
    const [userId, setUserIdState] = useState(null);


    useEffect(() => {
        const storedUserId = getUserId();
        setUserIdState(storedUserId);
        handleLogin();
        // const getuserId = cookies.userId;
        console.log("user id from cookies", userId);
    }, [userId]);

    const handleLogin = () => {
        const currentTime = new Date().getTime();
        const setuserId = `123`;
        // const newUserId = 'user123'; // Replace with actual user ID logic
        setUserId(userId);
        // setUserIdState(setuserId);
    };
    return (
         <Router >
        <div className = "App" >
        <Routes >
        <Route exact path = "/"
        element = { < ProductGrid />
        }
        /> 
        <Route path = "/product/:id"
        element = { < ProductPage />
        }
        /> 
        <Route path = "/category/:id"
        element = { < CategoryProductsPage />
        }
        /> 
        <Route path = "/Stepper/:totalPrice"
        element = { < Stepper />
        }
        />
        <Route path = "/cart"
        element = { < Cart />
        }
        /> 
        <Route path="/Stepperbaynow/:id" element={<Stepperbaynow />} />
        <Route path = "/payment/:totalPrice"
        element = { < PaymentPage />
        }
        />

        </Routes> 
        </div> 
        </Router>
    );
}

export default App;