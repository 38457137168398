import React, {
    useEffect,
    useState
} from 'react';
import {
    Box,
    Button,
    MenuItem,
    Select
} from '@mui/material';
import {
    useNavigate
} from 'react-router-dom'; // Import useNavigate hook
import {
    getUserId
} from './localStorageUtils';
import axios from 'axios';
import CartItem from './CartItem';

const OrderSummaryPage = () => {
    const navigate = useNavigate(); // Initialize useNavigate hook
    const iphone = 'https://project543.createinfotech.com' + '/assets/iphone.jpg';
    const plus = 'https://project543.createinfotech.com' + '/assets/iphone.jpg';
    const [age, setAge] = useState('');
    const [cartItems, setCartItems] = useState([]);
    const [totalPrice, setTotalPrice] = useState(0);
    const [userId, setUserIdState] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const handleChange = (event) => {
        setAge(event.target.value);
    };
    useEffect(() => {
        const storedUserId = getUserId();
        setUserIdState(storedUserId);

        const fetchData = async () => {
            if (!storedUserId) return;

            try {
                const storedUserId = getUserId();
                let data = new FormData();
                data.append('user_id', storedUserId);

                let config = {
                    method: 'post',
                    url: 'https://salertop.shop/admin/api/v1/list_cart',
                    headers: {
                        'Authorization': 'Basic ZmxpcGthcnQ6MjAkdGdic3YwOXU=',
                    },
                    data: data
                };

                const response = await axios.request(config);
                setCartItems(response.data.data || []);
                setTotalPrice(response.data.total);
            } catch (err) {
                console.error('API request error:', err.response ? err.response.data : err.message);
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);
    const handleAddSummary = () => {
        navigate('/payment'); // Use navigate function for redirection
    };

    return (<
        div style={
            {
                paddingBottom: "75px"
            }
        } >
        <h5> Deliver to: </h5> {
            loading ? (<
                p > Loading... </p>
            ) : error ? (<
                p > Error loading cart items. </p>
            ) : cartItems.length > 0 ? (
                cartItems.map(item => (
                    item.product && (<
                        CartItem key={
                            item.product.id
                        }
                        item={
                            item
                        }
                    />
                    )
                ))
            ) : (<
                p > Your cart is empty. </p>
            )
        }

        <
            div className="cart-summary"
            style={
                {
                    paddingLeft: "15px",
                    paddingRight: "15px"
                }
            } >
            <
                p style={
                    {
                        fontSize: "15px",
                        fontWeight: 500
                    }
                } > Cart Summary </p> <
                    div >
                <
                    p > &nbsp; Price Details </p> <
                        div className="price-item" >
                    <span > &nbsp; Price(1 item) </span> <
                        span > ₹{
                            totalPrice
                        } </span> </div> <
                            div className="price-item" >
                    <
                        span > &nbsp; Delivery Charges </span> <
                            span className="free-delivery" > FREE Delivery </span> </div> <
                                div className="price-item total" >
                    <span > &nbsp; Total Amount </span> <
                        span > ₹{
                            totalPrice
                        } </span> </div>
            </div>
            <div style={
                {
                    display: "flex",
                    justifyContent: "end"
                }
            } >
                <
                    Box sx={
                        {
                            mt: 2,
                            position: 'fixed',
                            bottom: 0,
                            left: 0,
                            right: 0,
                            width: '100%',
                            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
                            backgroundColor: 'white',
                            zIndex: 1000,
                            display: 'flex',
                            justifyContent: 'end',
                            p: 2,
                        }
                    } >
                    < Button
                        // onClick={handleAddToCart}
                        sx={
                            {
                                height: 'fit-content',
                                width: '10%',
                                textTransform: 'none',
                                color: 'black',
                                mr: 4,
                                width: "168px",
                                height: "40px",
                                font: 'normal',
                                background: 'rgba(255, 195, 3, 1)',
                                '&:hover': {
                                    backgroundColor: 'rgba(255, 195, 3, 1)',
                                },
                            }
                        } >
                        Place Order</Button> </Box> </div> </div> </div>
    );
};

export default OrderSummaryPage;