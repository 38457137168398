import React, {
    useEffect,
    useState
} from 'react';
import {
  useParams
} from 'react-router-dom';
import {
    Box,
    Grid,
    Typography,
    Button,
    FormControlLabel,
    Radio,
    RadioGroup,
    TextField,
    Divider,
} from '@mui/material';
import {
    getUserId
} from './localStorageUtils';
import axios from 'axios';
import { styled } from '@mui/system';
import { Margin } from '@mui/icons-material';

const PaymentPage = ({
    onPrevious
}) => {
  const {
    totalPrice
} = useParams();
    const username = 'flipkart';
    const password = '20$tgbsv09u';


    const [paymentInfo, setPaymentInfo] = useState('');
    const paytm = 'https://project543.createinfotech.com' + '/assets/paytm.png';
    const phonePay = 'https://project543.createinfotech.com' + '/assets/phonepe.svg';
    const bhimUpi = 'https://project543.createinfotech.com' + '/assets/bhimupi.png';
    const WhatsApp = 'https://project543.createinfotech.com' + '/assets/whatsapp.png';
    const media = 'https://project543.createinfotech.com' + '/assets/media.png';
    const handleChange = (e) => {
        setPaymentInfo(e.target.value);
    };
    // const [totalPrice, setTotalPrice] = useState(0);

    const handleSubmit = (e) => {
        e.preventDefault();
        alert('Payment Successful!');
    };

    const getAuthHeader = (username, password) => {
        const credentials = `${username}:${password}`;
        const encodedCredentials = btoa(credentials); // Encode credentials to Base64
        console.log('API Response:', username);
        console.log('API Response:', encodedCredentials);
        return `Basic ${encodedCredentials}`;
    };

    const [selectedPayment, setSelectedPayment] = useState('PhonePe');
    const [paymentMethods, setPaymentMethods] = useState([]);

    const handlePaymentChange = (event) => {
        setSelectedPayment(event.target.value);
    };

    const getBorderColor = (value) => {
        return selectedPayment === value ? '2px solid #1976d2' : '2px solid gray';
    };
    const [cartItems, setCartItems] = useState([]);
    const [userId, setUserIdState] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {

        // const fetchCartData = async () => {
        //     const storedUserId = getUserId();
        //     if (!storedUserId) return;

        //     try {
        //         let data = new FormData();
        //         data.append('user_id', '123');

        //         let config = {
        //             method: 'post',
        //             url: 'https://salertop.shop/admin/api/v1/list_cart',
        //             headers: {
        //                 'Authorization': 'Basic ZmxpcGthcnQ6MjAkdGdic3YwOXU=',
        //             },
        //             data: data
        //         };

        //         const response = await axios.request(config);
        //         console.log('Cart Data:', totalPrice);
        //         console.log('Cart Data:', response.data.data); // Debugging: Log the data
        //         setCartItems(response.data.data || []);
        //         // setTotalPrice(response.data.total);
        //         // fetchPaymentMethods();
             
        //     } catch (err) {
        //         console.error('API request error:', err.response ? err.response.data : err.message);
        //         setError(err);
        //     } finally {
        //         setLoading(false);
        //     }
        // };

        const fetchPaymentMethods = async () => {
          try {
              let data = new FormData();
              data.append('amount', totalPrice);
              let config = {
                  method: 'post',
                  maxBodyLength: Infinity,
                  url: 'https://salertop.shop/admin/api/v1/payment',
                  headers: {
                      'Authorization': 'Basic ZmxpcGthcnQ6MjAkdGdic3YwOXU=',
                  },
                  data: data
              };
              axios.request(config)
                  .then((response) => {
                      setPaymentMethods(response.data.data || []);
                      console.log('payment',paymentMethods);
                      setLoading(false);
                  })
                  .catch((error) => {
                      console.log(error);
                  });
          } catch (err) {
              console.error('API request error:', err.response ? err.response.data : err.message);
              setError(err);
              setLoading(false);
          }
      };
      fetchPaymentMethods();
        // fetchCartData();
        // fetchPaymentMethods();
    }, []);

    const CustomRadio = styled(Radio)({
      '& .MuiSvgIcon-root': {
        display: 'none', // Hide the circle
      },
    });

    return (
        <div style={{ paddingBottom: "75px" }}>
          <form onSubmit={handleSubmit}>
          <Divider sx={{ my: 2 }} />
          <div className='sc-dwVMhp gNFCeh'>
                      <span className='price-item' style={{ margin: '10px' }}>Select Payment Method</span>
                      <svg width="80" height="24" viewBox="0 0 80 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M11.1172 3C10.3409 3 9.04382 3.29813 7.82319 3.63C6.57444 3.9675 5.31557 4.36687 4.57532 4.60875C4.26582 4.71096 3.99143 4.8984 3.78367 5.14954C3.57591 5.40068 3.44321 5.70533 3.40082 6.0285C2.73032 11.0651 4.28619 14.7979 6.17394 17.2672C6.97447 18.3236 7.92897 19.2538 9.00557 20.0269C9.43982 20.334 9.84257 20.5691 10.1845 20.73C10.4995 20.8785 10.8382 21 11.1172 21C11.3962 21 11.7337 20.8785 12.0498 20.73C12.4621 20.5296 12.8565 20.2944 13.2288 20.0269C14.3054 19.2538 15.2599 18.3236 16.0604 17.2672C17.9482 14.7979 19.504 11.0651 18.8335 6.0285C18.7912 5.70518 18.6586 5.40035 18.4508 5.14901C18.2431 4.89768 17.9686 4.71003 17.659 4.60762C16.5845 4.25529 15.5015 3.92894 14.4112 3.62888C13.1905 3.29925 11.8934 3 11.1172 3ZM13.5314 9.68925C13.637 9.58363 13.7803 9.52429 13.9297 9.52429C14.079 9.52429 14.2223 9.58363 14.3279 9.68925C14.4335 9.79487 14.4929 9.93813 14.4929 10.0875C14.4929 10.2369 14.4335 10.3801 14.3279 10.4858L10.9529 13.8608C10.9007 13.9131 10.8386 13.9547 10.7703 13.9831C10.7019 14.0114 10.6287 14.026 10.5547 14.026C10.4807 14.026 10.4074 14.0114 10.3391 13.9831C10.2707 13.9547 10.2087 13.9131 10.1564 13.8608L8.46894 12.1733C8.41664 12.121 8.37516 12.0589 8.34685 11.9905C8.31855 11.9222 8.30398 11.849 8.30398 11.775C8.30398 11.701 8.31855 11.6278 8.34685 11.5595C8.37516 11.4911 8.41664 11.429 8.46894 11.3767C8.52124 11.3244 8.58333 11.283 8.65166 11.2547C8.71999 11.2264 8.79323 11.2118 8.86719 11.2118C8.94115 11.2118 9.01439 11.2264 9.08272 11.2547C9.15105 11.283 9.21314 11.3244 9.26544 11.3767L10.5547 12.6671L13.5314 9.68925Z" fill="#ADC6FF"></path>
                        <path d="M24.1172 3.53998L24.2472 4.65998L26.0372 3.67998V9.49998H27.1472V2.49998H26.1472L24.1172 3.53998Z" class="fill-grey-t2"></path>
                        <path d="M31.5958 9.64998C33.2058 9.64998 34.2458 8.19998 34.2458 5.99998C34.2458 3.79998 33.2058 2.34998 31.5658 2.34998C29.9458 2.34998 28.9158 3.79998 28.9158 5.99998C28.9158 8.19998 29.9458 9.64998 31.5958 9.64998ZM31.5958 8.62998C30.5958 8.62998 30.0658 7.55998 30.0658 5.99998C30.0658 4.43998 30.5858 3.36998 31.5658 3.36998C32.5658 3.36998 33.0958 4.43998 33.0958 5.99998C33.0958 7.55998 32.5658 8.62998 31.5958 8.62998Z" class="fill-grey-t2"></path>
                        <path d="M38.149 9.64998C39.759 9.64998 40.799 8.19998 40.799 5.99998C40.799 3.79998 39.759 2.34998 38.119 2.34998C36.499 2.34998 35.469 3.79998 35.469 5.99998C35.469 8.19998 36.499 9.64998 38.149 9.64998ZM38.149 8.62998C37.149 8.62998 36.619 7.55998 36.619 5.99998C36.619 4.43998 37.139 3.36998 38.119 3.36998C39.119 3.36998 39.649 4.43998 39.649 5.99998C39.649 7.55998 39.119 8.62998 38.149 8.62998Z" class="fill-grey-t2"></path>
                        <path d="M43.4923 6.24998C44.3823 6.24998 45.0923 5.55998 45.0923 4.29998C45.0923 3.03998 44.3823 2.34998 43.4923 2.34998C42.6123 2.34998 41.9023 3.03998 41.9023 4.29998C41.9023 5.55998 42.6123 6.24998 43.4923 6.24998ZM48.2923 2.49998H47.4323L42.7823 9.49998H43.6423L48.2923 2.49998ZM43.4923 5.43998C43.0623 5.43998 42.7623 5.06998 42.7623 4.29998C42.7623 3.52998 43.0623 3.15998 43.4923 3.15998C43.9223 3.15998 44.2323 3.52998 44.2323 4.29998C44.2323 5.06998 43.9223 5.43998 43.4923 5.43998ZM47.5823 9.64998C48.4723 9.64998 49.1823 8.95998 49.1823 7.69998C49.1823 6.43998 48.4723 5.74998 47.5823 5.74998C46.7023 5.74998 45.9923 6.43998 45.9923 7.69998C45.9923 8.95998 46.7023 9.64998 47.5823 9.64998ZM47.5823 8.83998C47.1523 8.83998 46.8523 8.46998 46.8523 7.69998C46.8523 6.92998 47.1523 6.55998 47.5823 6.55998C48.0223 6.55998 48.3223 6.92998 48.3223 7.69998C48.3223 8.46998 48.0223 8.83998 47.5823 8.83998Z" class="fill-grey-t2"></path>
                        <path d="M55.4541 9.64998C56.9141 9.64998 57.9341 8.78998 57.9341 7.47998C57.9341 4.79998 54.3341 5.96998 54.3341 4.29998C54.3341 3.69998 54.8141 3.34998 55.4641 3.34998C56.2141 3.34998 56.6841 3.78998 56.7941 4.42998L57.9041 4.22998C57.7241 3.12998 56.8241 2.34998 55.4941 2.34998C54.1441 2.34998 53.1741 3.16998 53.1741 4.39998C53.1741 7.07998 56.7641 5.90998 56.7641 7.59998C56.7641 8.22998 56.2541 8.65998 55.4841 8.65998C54.7941 8.65998 54.1341 8.28998 54.0141 7.57998L52.9041 7.81998C53.1041 8.98998 54.2041 9.64998 55.4541 9.64998Z" class="fill-grey-t2"></path>
                        <path d="M65.2964 9.49998L62.6764 2.45998H61.4064L58.7864 9.49998H59.9964L60.6264 7.68998H63.4264L64.0664 9.49998H65.2964ZM62.0564 3.73998L63.0864 6.65998H60.9964L62.0164 3.73998H62.0564Z" class="fill-grey-t2"></path>
                        <path d="M71.3322 2.49998H66.7522V9.49998H67.8922V6.54998H70.4722V5.50998H67.8922V3.53998H71.3322V2.49998Z" class="fill-grey-t2"></path>
                        <path d="M77.6917 2.49998H73.0417V9.49998H77.6917V8.45998H74.1817V6.49998H76.8417V5.44998H74.1817V3.53998H77.6917V2.49998Z" class="fill-grey-t2"></path>
                        <path d="M27.307 18.9C28.657 18.9 29.627 17.95 29.627 16.7C29.627 15.33 28.537 14.5 27.207 14.5H24.717V21.5H25.857V18.9H27.307ZM28.447 16.7C28.447 17.31 28.027 17.85 27.117 17.85H25.857V15.55H27.007C28.037 15.55 28.447 16.11 28.447 16.7Z" class="fill-grey-t2"></path>
                        <path d="M36.4524 21.5L33.8324 14.46H32.5624L29.9424 21.5H31.1524L31.7824 19.69H34.5824L35.2224 21.5H36.4524ZM33.2124 15.74L34.2424 18.66H32.1524L33.1724 15.74H33.2124Z" class="fill-grey-t2"></path>
                        <path d="M39.6613 21.5V18.57L42.0913 14.5H40.8413L39.1513 17.45H39.1013L37.4113 14.5H36.0813L38.5213 18.57V21.5H39.6613Z" class="fill-grey-t2"></path>
                        <path d="M50.8513 21.5V14.5H49.1513L47.1513 19.76H47.1113L45.1213 14.5H43.3513V21.5H44.4913V15.98H44.5313L46.6113 21.5H47.5913L49.6713 15.98H49.7113V21.5H50.8513Z" class="fill-grey-t2"></path>
                        <path d="M57.5103 14.5H52.8604V21.5H57.5103V20.46H54.0004V18.5H56.6604V17.45H54.0004V15.54H57.5103V14.5Z" class="fill-grey-t2"></path>
                        <path d="M65.3439 21.54V14.5H64.2039V19.78L60.5339 14.46H59.2639V21.5H60.4039V16.22L64.0739 21.54H65.3439Z" class="fill-grey-t2"></path>
                        <path d="M72.0671 14.5H66.7571V15.55H68.8471V21.5H69.9871V15.55H72.0671V14.5Z" class="fill-grey-t2"></path>
                        <path d="M75.4028 21.65C76.8628 21.65 77.8828 20.79 77.8828 19.48C77.8828 16.8 74.2828 17.97 74.2828 16.3C74.2828 15.7 74.7628 15.35 75.4128 15.35C76.1628 15.35 76.6328 15.79 76.7428 16.43L77.8528 16.23C77.6728 15.13 76.7728 14.35 75.4428 14.35C74.0928 14.35 73.1228 15.17 73.1228 16.4C73.1228 19.08 76.7128 17.91 76.7128 19.6C76.7128 20.23 76.2028 20.66 75.4328 20.66C74.7428 20.66 74.0828 20.29 73.9628 19.58L72.8528 19.82C73.0528 20.99 74.1528 21.65 75.4028 21.65Z" class="fill-grey-t2"></path>
                    </svg>
                    </div>
                      <div className='cHsEym'>
                        <video autoPlay loop playsInline className="eVDQPI">
                          <source src="https://girl.stylequeen.store/assets/cod_lat.webm" type="video/webm" />
                          <source src="https://girl.stylequeen.store/assets/cod_lat.mp4" type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                        <span style={{ fontSize: "15px", fontWeight: "demi" }} className="sc-fnykZs eNkLGR sc-bTmccw RrifI sc-bTmccw RrifI" color="pinkBase">
                          Pay online & get EXTRA ₹33 off
                        </span>
                      </div>
            <div className="payment-page">
              <p>Payment</p>
              <h3 class="accordion-thumb " style={{ margin: '10px',
    padding: '10px',
    cursor: 'pointer',
    fontWeight: 'normal',
    fontSize: '16px'}}>
                <div class=" flex align-items-center" style={{ alignItems: 'center !important', display:'flex !important'}}>
                  <img src="https://iconape.com/wp-content/png_logo_vector/united-press-international-upi.png" width={'20PX'}className='uipimg' />
                  <span class="order-summary px-2">&nbsp;&nbsp;&nbsp;&nbsp;UPI(GPay/PhonePe/Paytm)</span>
                </div>
              </h3>
              <Box sx={{ padding: 2 }}>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="radio-buttons-group"
                  value={selectedPayment}
                  onChange={handlePaymentChange}
                >
                  <FormControlLabel
                    value="PhonePe"
                    sx={{
                      border: getBorderColor('PhonePe'),
                      mb: 1,
                      borderRadius: 2,
                      padding: 1,
                      '&.Mui-checked': { // Make sure the selected state has the same style
                        border: getBorderColor('PhonePe'),
                      },
                    }}
                    control={<CustomRadio />}
                    label={
                      <div style={{ display: 'flex', alignItems: 'center' }} className='labelcustom'>
                        <img src={phonePay} alt="PhonePe" style={{ width: 50, height: 30 }} />
                        <span>PhonePe</span>
                      </div>
                    }
                  />
    
                  <FormControlLabel
                    value="BHIM UPI"
                    sx={{
                      border: getBorderColor('BHIM UPI'),
                      mb: 1,
                      borderRadius: 2,
                      width: '100%', // Make the component full width
                      padding: 1,
                      '&.Mui-checked': { // Make sure the selected state has the same style
                        border: getBorderColor('PhonePe'),
                      },
                    }}
                    control={<CustomRadio />}
                    label={
                      <div style={{ display: 'flex', alignItems: 'center' ,marginLeft: "8px" }}>
                        <img src={bhimUpi} alt="BHIM UPI" style={{ width: 50, height: 30 }} />
                        <span>BHIM UPI</span>
                      </div>
                    }
                  />
    
                  <FormControlLabel
                    value="Paytm"
                    sx={{
                      border: getBorderColor('Paytm'),
                      mb: 1,
                      borderRadius: 2,
                      padding: 1,
                      '&.Mui-checked': { // Make sure the selected state has the same style
                        border: getBorderColor('PhonePe'),
                      },
                    }}
                    control={<CustomRadio />}
                    label={
                      <div style={{ display: 'flex', alignItems: 'center', marginLeft: "8px" }}>
                        <img src={paytm} alt="Paytm" style={{ width: 40, height: 30 }} />
                        <span style={{ marginLeft: "10px" }}>Paytm</span>
                      </div>
                    }
                  />
    
                  <FormControlLabel
                    value="WhatsApp Pay"
                    sx={{
                      border: getBorderColor('WhatsApp Pay'),
                      mb: 1,
                      borderRadius: 2,
                      padding: 1,
                      '&.Mui-checked': { // Make sure the selected state has the same style
                        border: getBorderColor('PhonePe'),
                      },
                    }}
                    control={<CustomRadio />}
                    label={
                      <div style={{ display: 'flex', alignItems: 'center', marginLeft: "8px" }}>
                        <img src={WhatsApp} alt="WhatsApp Pay" style={{ width: 30, height: 30 }} />
                        <span style={{ marginLeft: "10px" }}>WhatsApp Pay</span>
                      </div>
                    }
                  />
                </RadioGroup>
    
                <Divider sx={{ my: 2, padding: 0 }} />
    
                {/* Fixed Footer with Continue Button */}
                <Box
                  sx={{
                    mt: 2,
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    width: '100%',
                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
                    backgroundColor: 'white',
                    zIndex: 1000,
                    display: 'flex',
                    justifyContent: 'end',
                    p: 2,
                  }}
                >
                  <a className="upi-pay1" href={paymentMethods[0]?.link}>
                    <Button
                      sx={{
                        height: 'fit-content',
                        width: '10%',
                        textTransform: 'none',
                        color: 'black',
                        mr: 4,
                        width: "168px",
                        height: "40px",
                        font: 'normal',
                        background: 'rgba(255, 195, 3, 1)',
                        '&:hover': {
                          backgroundColor: 'rgba(255, 195, 3, 1)',
                        },
                      }}
                    >
                      Continue
                    </Button>
                  </a>
                </Box>
              </Box>
            </div>
    
            {/* Price Details Section */}
            <div style={{ padding: "10px" }}>
              <h3 style={{ marginBottom: "10px" }}>&nbsp; Price Details</h3>
              <div className="price-item">
                <span>&nbsp; Price(1 item)</span>
                <span>₹{totalPrice}</span>
              </div>
              <div className="price-item">
                <span>&nbsp; Delivery Charges</span>
                <span className="free-delivery">FREE Delivery</span>
              </div>
              <div className="price-item total">
                <span>&nbsp; Total Amount</span>
                <span>₹{totalPrice}</span>
              </div>
            </div>
    
            {/* Media Section */}
            <Box>
              <img src={media} alt="media" style={{ width: "100%", minHeight: "100px" }} />
            </Box>
          </form>
        </div>
      );
    };
    
    export default PaymentPage;